(function(angular){

	/**
	 * Service des documents
	 */
	function documentService($http, Upload){
		
		/** Upload des documents pour les etapes */
		this.uploadDocument = function(file, etapePatientId, rubrique){
	        return Upload.upload({
	            url: 'mobile/documents/uploadDocument',
	            data: {file: file, 'etapePatientID': etapePatientId, 'rubrique': rubrique, 'filename' : encodeURIComponent(file.name)}
	        })
		}
		
		this.deleteDocument = function(documentPatientId){
			// Pour IE_8.......
			// return $http.delete('cp-patient/v1/document/deleteDocument?idDocument=' + documentPatientId);
			return $http({method: 'DELETE', url:'mobile/documents/deleteDocument?idDocument=' + documentPatientId});
		}
		
		/**
		 * Retourne les infos 
		 */
		this.getListSejoursInfosAdministratifs = function(){
			return $http.get('mobile/documents/documents-administratifs');
		}
		
		/**  Récupération des pièces jointes du patient*/
		this.getPatientPiecesJointes = function(){
			return $http.get('mobile/pieceJointe/list');
		}
		
		/**  Delete une pièce jointe du patient */
		this.deletePieceJointe = function(typePiece, numeroPiece){
			return $http({method: 'DELETE', url:'mobile/pieceJointe/delete-piece-jointe', params: {'numeroPiece': numeroPiece, 'typePiece': typePiece}
		});
		}
		
	}
	
	documentService.$inject = ['$http', 'Upload'];

	angular
	    .module('clientApp')
	    .service('documentService', documentService);
	
})(angular);