(function(angular){
	angular.module('clientApp').component('logoComponent', {
		templateUrl: "./js/component/logo/logoComponent.html",
		controller: LogoComponentController,
		bindings: {
			alt:'@',
			src: '@',
			lang: '@',
			height:'@?',
			clazz:'@?',
			etablissement:'@?',
		}
	});
	LogoComponentController.$inject = ['parametersService'];

	/**
	 * Directive pour récupérer les clés de parametrage en base et les afficher en html
	 */
	function LogoComponentController(parametersService) {
		var ctrl = this;
		
		this.$onInit = function () {
			parametersService.getParamByKeyLang(this.src, this.lang).then(function(data){
				ctrl.file = (data.value === undefined ? this.src : data.value);
			});
			if (this.alt !== undefined) {
				parametersService.getParamByKeyLang(this.alt, this.lang).then(function(data){
					ctrl.alt = (data.value === undefined ? this.alt : data.value);
				});	    
			}
		};
	}
})(angular);