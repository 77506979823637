(function(angular){
		
	/**
	 *  Filter remove dernier element
	 *  @param input est un string
	 */
	function removePointFilter(){
		return function(input) {
			if (input != null) {
				return input.substr(0, input.length - 1);
			}
			return null;
		}
	}
		
	removePointFilter.$inject = [];
		
	angular.module('clientApp')
		.filter('removePoint', removePointFilter);

})(angular);