(function(angular){
	angular.module('clientApp').component('contactsAdmin', {
		templateUrl: "./js/component/contacts/contactsAdmin.html",
		controller: ContactsAdminController,
		bindings: {	}
	});
	ContactsAdminController.$inject = ['contactService', '$http', '$scope'];


	function ContactsAdminController(contactService, $http, $scope) {
		var ctrl = this;
		
		this.$onInit = function () {
			contactService.getContactsAdmin().then(function(res){
				ctrl.contactsAdmin = res.data.data;			
			});
		};
		
	}
})(angular);