(function(angular){

	function echangeService($http, CommunService){
		
		// chargement des échanges 
		this.actionChargerEchange = function() {
			$http.get("mobile/echange/all").then(function(response) {
				CommunService.echanges = response.data;
	
				CommunService.echangesNonLus = [];
				var nbEchangesCliniqueNonLus = 0;
				var nbEchangesFamilleNonLus = 0;
				if (CommunService.echanges) {
					for (var i = 0; i < CommunService.echanges.length; i++) {
						echange = CommunService.echanges[i];
						var contactId = 0;
						if (echange.contactMedicalId) {
							contactId = echange.contactMedicalId;
						}
						if (echange.messageLu === false) {
							if ("FAMILLE" === echange.source) {
								nbEchangesFamilleNonLus++;
							} else if ("CLINIQUE" === echange.source && "PATIENT" === echange.destination) {
								if (CommunService.echangesNonLus[contactId]) {
									CommunService.echangesNonLus[contactId]++;
								} else {
									CommunService.echangesNonLus[contactId] = 1;
								}
								nbEchangesCliniqueNonLus++;
							}
						}
					}
				}
				CommunService.notifEchangesClinique = nbEchangesCliniqueNonLus;
				CommunService.notifEchangesFamille = nbEchangesFamilleNonLus;
			})
		};
	}

	echangeService.$inject = ['$http', 'CommunService'];

	angular
	    .module('clientApp')
	    .service('echangeService', echangeService);
	
})(angular);

