(function(angular){
	
	/**
	 * Service de session de l'espace patient
	 * @param $window
	 * @param $http
	 * @returns
	 */
	function sessionService($window, $http){
		var localStorage = $window.localStorage;
		
		
		try{
			this._user = JSON.parse(localStorage.getItem('session.user'));
			this._prochainSejourId = JSON.parse(localStorage.getItem('session.sejour.prochain.id'));
		    this._accessToken = localStorage.getItem('session.accessToken');
		    this._cookieRGPD = localStorage.getItem('session.cookieRGPD');
		}
		catch (e) {
			this._user = null;
			this._accessToken = null;
			this._cookie = null;
		}
		
		
		this.getUser = function(){
			return this._user;
		}

		this.setUser = function(user){
			if(user){
				user.password = null;
			}
			this._user = user;
	        localStorage.setItem('session.user', JSON.stringify(user));
	        return this;
		}
		
		this.getProchainSejourId = function(){
			return this._prochainSejourId;
		}

		this.setProchainSejourId = function(sejourId){
			this._prochainSejourId = sejourId;
	        localStorage.setItem('session.sejour.prochain.id', sejourId);
	        return this;
		}
		
	    this.getAccessToken = function(){
	    	return this._accessToken;
	    }

	    this.setAccessToken = function(token){
	        this._accessToken = token;
	        localStorage.setItem('session.accessToken', token);
	        return this;
	    }
	    
	    // Cookie pour la mise en confirmité RGPD
	    this.getCookieRGPD = function(){
	    	return this._cookieRGPD;
	    }

	    this.setCookieRGPD = function(){
	        this._cookieRGPD = new Date();
	        localStorage.setItem('session.cookieRGPD', 'Accepter le' + new Date());
	        return this;
	    }
	    
	    this.setCookieRGPDNot = function(){
	        this._cookieRGPD = new Date();
	        localStorage.setItem('session.cookieRGPD', 'Refuser le ' + new Date());
	        return this;
	    }
		
	    this.destroy = function(){
	    	this.setUser(null);
	      	this.setAccessToken(null);
	      	this.setProchainSejourId(null);
			$window.location.reload();
	    }
	}

	sessionService.$inject = ['$window', '$http'];

	angular
	    .module('clientApp')
	    .service('sessionService', sessionService);
	
})(angular);