(function(angular){
		
	/**
	 * Directive pour les etapes
	 */
	function etapeDirective(sessionService, CIRCUIT_ACTIONS, $filter, $location, circuitService, $sce, $window){
		
		/**
		 * Différence en jours entre 2 dates
		 */
		function differenceJoursEtapeDateJour(etape, maintenant){
			var dateEtape = getDateEtape(etape, maintenant);
			var dateJour = new Date();
			// difference en jours
			var difference = Math.round((dateJour - dateEtape)/(1000*60*60*24));
			
			return difference;
		}
		
		/**
		 * Retourne l'information de moment sur l'étape (information à afficher sur la droite de l'étape)
		 */
		function getInfoEtape(etape, maintenant){
			var dateMoment = getDateEtape(etape, maintenant);
			
			var dateJour = dateMoment ? $filter("date")(dateMoment, "dd/MM/yyyy HH:mm") : null;
			if(!maintenant && !etape.facultative){
				return dateMoment ? ("Prévu le " + dateJour) : "";
			}
			else if(etape.dateValidation){
				return "Fait le " + dateJour;
			}
			else if(maintenant && !etape.facultative){
				var infoMaintenant;
				// 1- cas avec date
				if(etape.date){
					infoMaintenant ="Avant le " + dateJour;
				}
				// 2- cas avec dateMin et dateMax
				else if(etape.dateMin && etape.dateMax){
					var dateMin = $filter("date")(etape.dateMin , "dd/MM/yyyy");
					infoMaintenant ="Entre le " + dateMin + " et le " + dateJour;
				}
				// 3- cas dateMax
				else if(etape.dateMax){
					infoMaintenant ="Avant le " + dateJour;
				}
				// 4- cas dateMin
				else if(etape.dateMin){
					var dateMin = $filter("date")(etape.dateMin , "dd/MM/yyyy");
					infoMaintenant ="Après le " + dateMin;
				}
				else{
					infoMaintenant = "";
				}
				
				return infoMaintenant;
			}
//			else if(etape.facultative){
//				return "Si besoin...";
//			}
		}
		
		/**
		 * Retourne la date de l'étape (en fonction du moment)
		 */
		function getDateEtape(etape, maintenant){
			var dateEtape;
			if(!maintenant){
				if(etape.date){
					dateEtape = new Date(etape.date);
				}
				else if(etape.dateMin){
					dateEtape = new Date(etape.dateMin);
				}
			}
			else if(etape.dateValidation){
				dateEtape = new Date(etape.dateValidation);
			}
			else if(maintenant){
				if(etape.date){
					dateEtape = new Date(etape.date);
				}
				else if(etape.dateMax){
					dateEtape = new Date(etape.dateMax);
				}
			}
			
			return dateEtape;
		}
		
		/** Retourne la classe css de l'étape (en fonction du moment) */
		function getClassEtape(etape, difference, maintenant){
			var css;
			if(!maintenant){
				css = "ensuite";
			}
			else if(etape.dateValidation){
				css = !etape.annulationPossible ? "historique" : "historique-annulation";
			}
			else if(maintenant){
				if(difference >= -1){
					css = "retard";
				}
			}
			return css;
		}
		
		return{
			  restrict:'EA',
			  
			  scope:{
				  etape : '=',
				  wrapperEtapeCourante : '=',
				  isMaintenant : '&',
				  chargerCircuits : '&',
				  initChampSaisieValeur : '&',
				  resetDocument : '&',
			  },
			  
			  templateUrl : './js/directive/etapeDirective.html',
			  
			  link: function(scope, elem, attrs) {
				  // token pour les documents
				  scope.token = sessionService.getAccessToken();
				  
				  // indique si l'étape est à faire maintenant
				  scope.maintenant = scope.isMaintenant({etape: scope.etape}) && !scope.etape.dateValidation;
				  
				  // initalisation partie droite : complement-info
				  scope.infoMoment = getInfoEtape(scope.etape, scope.maintenant);
				  
				  // initalisation du css de l'étape
				  scope.differenceJour = differenceJoursEtapeDateJour(scope.etape, scope.maintenant);
				  
				  // Si etape non facultative : code couleur pour les retards
				  if(!scope.etape.facultative){
					  elem.addClass(getClassEtape(scope.etape, scope.differenceJour, scope.maintenant));
				  }
				  
				  /**
				   * Clic sur le boutton droit et non l'ensemble de la directive
				   */
				  scope.clicBouton = function(){
					  scope.actionEtape(true);
					  // Pour ne charger le contenu des étapes qu'au clic
					//  circuitService.getEtapePatient(scope.etape.id).then(function(response){
					//	  scope.etape = response.data;
					//	  scope.actionEtape(true);
					//  });
				  }
				  
				  /**
				   * Clic sur la case à cocher, a le même comportement que le clic sur le bouton
				   * sauf dans le cas des etapes avec upload de document
				   */
				  scope.clicRadioBouton = function(){
					  //scope.actionEtape(false);
					  //TODO: Pour le moment on doit valider l'étape comme pour clicBouton().
					  scope.actionEtape(true);
				  }
				  
				  /**
				   * Appelé sur le clic boutton et radio boutton
				   */
				  scope.actionEtape = function(upload) {
					  scope.wrapperEtapeCourante.etapeCourante = scope.etape;
					  scope.wrapperEtapeCourante.annulation = false;
					  scope.wrapperEtapeCourante.lien = null;
					  scope.wrapperEtapeCourante.upload = false;
					  scope.wrapperEtapeCourante.statut = undefined;
					  scope.wrapperEtapeCourante.checkConsentement = false;
					  scope.wrapperEtapeCourante.consentement = false;
					  scope.wrapperEtapeCourante.donneAvis = false;
					  scope.wrapperEtapeCourante.constantes = false;
					  scope.wrapperEtapeCourante.questionnaire = false;

					  // clic possible si maintenant
					  //if(scope.maintenant || scope.etape.facultative){
						  var titrePopUp = scope.etape.nom;
						  var sejourId = sessionService.getUser().prochainSejour ? sessionService.getUser().prochainSejour.sejourId : 0;
						  // si le code de l'étape nécessite une action pour ouverture d'un onglet dans l'espace patient
						  if(CIRCUIT_ACTIONS.indexOf(scope.etape.code) != -1){
							  if(scope.etape.code == "PREADMISSION"){
								  // Dans le cas de la préad, on n'affiche pas la popup
								  $location.path("/hospitalisations/preadmission/" + sejourId);
								  return;
							  }
							  else if (scope.etape.code == "MESSAGERIE"){
								  scope.wrapperEtapeCourante.lien = "/messages/messagesClinique";
								  $window.parent.postMessage("messagerie", '*');
							  }
							  else if (scope.etape.code == "UPLOAD"){
								  scope.resetDocument();
								  scope.wrapperEtapeCourante.upload = upload;
							  }
							  else if (scope.etape.code == "CEQUEJEDOISSAVOIR"){
								  scope.wrapperEtapeCourante.lien = "/hospitalisations/cequejedoissavoir/" + sejourId;
							  }
							  //affichage d'une pop-up speciale pour le code PERSONNEAPREVENIR
							  else if(scope.etape.code == "PERSONNEAPREVENIR"){
								  $('#modalFichePrevenir').on('hidden.bs.modal', function (e) {
										  scope.chargerCircuits();
									  }).modal('show');
							  }
							  else if(scope.etape.code == "CONSENTEMENT"){
								  scope.wrapperEtapeCourante.checkConsentement = true;
								  scope.wrapperEtapeCourante.consentement = false;
								  
								  // Création du document a signer
								  circuitService.getURLConsentementEclaire(scope.wrapperEtapeCourante.etapeCourante).then(function(response){
									  
									  scope.wrapperEtapeCourante.urlConsentement = $sce.trustAsResourceUrl(response.data);
									  
									// Affichage de la popup
									  $('#modalEtape').on('hidden.bs.modal', function (e) {
										  scope.chargerCircuits();
									  }).modal('show');
									});
								  
							  }
							  else if (scope.etape.code == "DOCUMENT_ADMINISTRATIF"){
								  scope.wrapperEtapeCourante.lien = "/documents/administratifs";
							  }
							  else if (scope.etape.code == "DOSSIER_MEDICAL"){
								  scope.wrapperEtapeCourante.lien = "/documents/dossier-medical";
							  }
							  else if (scope.etape.code == "AVIS"){
								  scope.wrapperEtapeCourante.donneAvis = true;
							  }
							  else if(scope.etape.code == "CONSTANTES"){
								  scope.wrapperEtapeCourante.constantes = true;
								  scope.initChampSaisieValeur();
							  }
							  // ajout de la constante : questionnaire
							  else if(scope.etape.code == "QUESTIONNAIRE"){
								  scope.wrapperEtapeCourante.questionnaire = true;
								  scope.initChampSaisieValeur();
							  }
						  }
						  // dans les autres cas : ouverture d'une pop-up pour la validation de l'étape
						  else {
							  // l'etape parent existe et est modifiable
							  if(scope.etape.parent && scope.etape.parentModifiable){
								  scope.etape.parent.date = null;
								  titrePopUp = "Veuillez indiquer la date et l'heure";
							  }
						  }
						  
						  // ouverture de la pop-up si pas de personne à prevenir
						  if(scope.etape.code !== "PERSONNEAPREVENIR" && scope.etape.code !== "CONSENTEMENT"){
							  $('#modalEtape').on('hidden.bs.modal', function (e) {
								  // Arret de la vidéo
								  $("#modalEtape iframe").attr("src", $("#modalEtape iframe").attr("src"));
							  }).modal('show');
						  }
//					  }
					  // clic possible si fait && annulationPossible
//					  else if(scope.etape.dateValidation && scope.etape.annulationPossible){
//						  scope.wrapperEtapeCourante.annulation = true;
//						  $('#modalEtape').on('hidden.bs.modal', function (e) {
//							// Arret de la vidéo
//							  $("#modalEtape iframe").attr("src", $("#modalEtape iframe").attr("src"));
//						  }).modal('show');
//					  }
					  // ( on ne peut rien faire pour les etapes : ensuite )
				  }
				  
			  }
		  }
	}
		
	etapeDirective.$inject = ['sessionService', 'CIRCUIT_ACTIONS', '$filter', '$location', 'circuitService', '$sce', '$window'];
		
	angular.module('clientApp')
		.directive('etapeDirective', etapeDirective);

})(angular);