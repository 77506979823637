(function(angular){
	function contactService($http, $q ){
		
		this.getContactsAdmin = function(){
			var deferred = $q.defer();
			$http.get("mobile/contact/get-admins")
				.then(function(data) {
					  deferred.resolve({data: data});
					  console.log('getContactsAdmin sucess');
		       }, function (msg, code) {
		          deferred.reject(msg);
		          console.log('getContactsAdmin echec');
		       });
			return deferred.promise;
		}
	}

	contactService.$inject = ['$http', '$q'];
	angular
	    .module('clientApp')
	    .service('contactService', contactService);
	
})(angular);
