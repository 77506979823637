(function(){
	 'use strict';

	angular.module("clientApp", ["ngRoute", "ngSanitize", "monospaced.elastic", "ui.bootstrap", "rwdImageMaps", "angular.filter", "ngFileUpload", "angularFileUpload", "angular-loading-bar", "angulartics", "angulartics.google.analytics", "ui.select"])
		// Gestion du 401 quand on essaye d'accéder aux services en étant déconnecté.
	 	// Gestion du 500 pour les receptions d'erreur
	.factory('authHttpResponseInterceptor',['$q', '$rootScope', '$location', function($q, $rootScope, $location){
    return {
        response: function(response){
            if (response.status === 401) {
            }
            return response || $q.when(response);
        },
        responseError: function(rejection) {
            if (rejection.status === 401) {
				if ($rootScope.loginService.isLoggedIn()){
					$rootScope.session.destroy();
					$rootScope.location.path("/");
					$window.location.reload();
					
					// Fermeture des modals et suppression du backdrop	angular.module("clientApp", ["ngRoute", "ngSanitize", "monospaced.elastic", "ui.bootstrap", "rwdImageMaps", "angular.filter", "ngFileUpload", "angularFileUpload", "angular-loading-bar", "angulartics", "angulartics.google.analytics", "ui.select", "ngMaterial"])

					$('.modal-backdrop').remove()
					$rootScope.$broadcast("EspacePatientError", "Votre session est terminée");
					
				}
		    }
            if (rejection.status === 500) {
            	$rootScope.$broadcast("EspacePatientError", rejection.data.message);
            }
            return $q.reject(rejection);
        }
    };
	}])
	.config([ '$locationProvider', function( $locationProvider) {
		$locationProvider.hashPrefix('');
	 }])
	.config(['$httpProvider',function($httpProvider) {
			$httpProvider.interceptors.push('authHttpResponseInterceptor');
			// no cache
		    if (!$httpProvider.defaults.headers.get) {
		        $httpProvider.defaults.headers.get = {};    
		    }  
		    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
		    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
	}])
	.config(['$analyticsProvider', function ($analyticsProvider) {
		}])
	// loader automatique
	.config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
		cfpLoadingBarProvider.spinnerTemplate = '<div class="modal-pasteur"></div>';
	}])
	.run(['$rootScope', '$location', 'loginService', 'sessionService', 'programmeSanteService', 'echangeService', 'AccueilService', 'CommunService', 'parametersService', '$anchorScroll', '$routeParams', '$http',
		function($rootScope, $location, loginService, sessionService, programmeSanteService, echangeService, AccueilService, CommunService, parametersService, $anchorScroll, $routeParams, $http) {
			// initialisation : on ajoute au rootScope : sessionService + loginService
			$rootScope.session = sessionService;
			$rootScope.loginService = loginService;
			$rootScope.parametersService = parametersService;
			$rootScope.location = $location;

//			var favoriteCookie = $cookieStore;
			
			
			console.log(JSON.stringify($location.search().appli));
			// Dans le cas ou l'on se connecte avec CP-Patient
			if ($location.search().appli==="mobile" && $location.search().token) {
				//maj du token
				$http.defaults.headers.common['Authorization'] = 'Bearer ' + $location.search().token;
				sessionService.setAccessToken($http.defaults.headers.common['Authorization']);
				
				if (!loginService.isLoggedIn()) {
					console.log("Récupération du patient");
					$rootScope.mobileApp = true;
					AccueilService.getPatientByToken($location.search().token).then(function(response) {
						var patient = response.data;					
						if (patient.patientId && (!patient.geminiNonInscrit && patient.inscriptionValidee)) {
							//maj du patient
							$rootScope.session.setUser(patient);
						}
						
					});
				}
			} else { 
				
				if(loginService.isLoggedIn()){
					// maj du token
					$http.defaults.headers.common['Authorization'] = sessionService.getAccessToken();
					
					// ---------- TODO : création DTO pour ces éléments, utilisés dans le menu ---------
					// charge les messages
					echangeService.actionChargerEchange();
					
					// ---------------------------------------------------------------------------------
				}
			}
			$rootScope.$on("$routeChangeStart", function(event, next, current) { 
				
				// Show a loading message until promises aren't resolved
				$rootScope.loadingView = true;
				
				// si route protégée et non autorisé
				if (!$rootScope.mobileApp && next.$$route.authorized && !loginService.isLoggedIn()){
					$location.path('/');
		            event.preventDefault();
				}
				
				//  login / inscription / mot de passe , non accessible lorsque loggué
		        if ( ($location.path() === '/' || $location.path() === '/inscription' || $location.path() === '/motDePasseOublie') &&  loginService.isLoggedIn() ){
		        	$location.path('accueil');
		            event.preventDefault();
		        }
			});
			
			$rootScope.$on("$routeChangeSuccess", function(newRoute, oldRoute) {
				// Hide loading message
				$rootScope.loadingView = false;
				
				// Dans le cas du retour d'une signature électronique
				if ($routeParams.mode === "SIGN")
				{
					$rootScope.hideNavigation = true;
				} else {
					$rootScope.hideNavigation = false;
				}
				
				$location.hash($routeParams.scrollTo);
				$anchorScroll();
			});
	}]);
	
	// directive cache, TODO : mettre dans son proppre fichier
	angular.module("clientApp").directive("ngIncludeNoCache", ['$templateCache', function($templateCache) {
		var directive = {
			restrict: "A",
			scope: false,
			link: function(scope, element, attributes) {
				scope.$parent.$watch(attributes.ngInclude, function(newValue, oldValue) {
					$templateCache.remove(oldValue);
				});
			}
		};
		return directive;
	}]);

})();