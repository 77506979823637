(function(angular){


	function programmeSanteController(CommunService, $filter, $location, $scope, $timeout, $http, AccueilService, programmeSanteService, MedecinGeneralisteService, sessionService) {
		$scope.Commun = CommunService;
		
		$scope.token = sessionService.getAccessToken();
		
		$scope.prenom = sessionService.getUser().prenom;
		$scope.prenom = $scope.prenom.charAt(0).toUpperCase() + $scope.prenom.slice(1).toLowerCase();
		
		/**
		 * Activation du programme de santé (après l'action d'abonnement)
		 * Peut ouvrir un questionnaire si il y a des champs de saisie formulaire
		 * @param programmeSante : le programme de santé à activer
		 */
		$scope.activerProgramme = function(programmeSante) {
			
			// si il y a des champs de saisie à saisir avant l'acivation
			// avec "ecran_questionnaire" à 1
			var programmeSanteChampSaisieQuestionnaire = programmeSante.programmeSanteChampSaisie ? $filter('filter')( programmeSante.programmeSanteChampSaisie, { ecranQuestionnaire: true}, true) : null;
			
			// fermeture de la popup du programme
			$("#modalProgrammeSante").on('hidden.bs.modal', function (e) {
				// si il ya des champs de saisie de type questionnaire
				if( programmeSante &&  programmeSanteChampSaisieQuestionnaire.length){
					$("#modalProgrammeSanteQuestionnaire").modal('show');
				}
				// sinon lancement du programme directemment
				else{
					$scope.actionAbonnement(programmeSante, false);
				}
			}).modal('hide');

			
			
		}
		
		$scope.redirectionAccueil = function(){
			$location.path("accueil");
		}
	
		 /** Abonnement au programme de santé 
		 * @param isQuestionnaire : indique si il y a un questionnaire avant l'activation (si oui, destroy de la pop-up
		 * @param 
		 */
		$scope.actionAbonnement = function(programmeSante, isQuestionnaire) {
		
			programmeSanteService.activerProgramme(programmeSante.id).then(function(response) {
				// maj de la date d'activation
				var progAbonnement = $filter('filter')( CommunService.psAbonnements, { programmeSante : { id :programmeSante.id} }, true)[0];
				progAbonnement.dateActivation = new Date();
				
				// création des champs saisies valeurs
				if(isQuestionnaire){
					$("#modalProgrammeSanteQuestionnaire").modal('hide');
					var champSaisieValeurs = CommunService.getChampSaisieValeurListe($scope.champSaisieValeurs, new Date());
					$scope.formInvalide = (champSaisieValeurs == null);
					
					// si le formulaire est valide
					if(!$scope.formInvalide){
						programmeSanteService.createChampSaisieValeur(champSaisieValeurs);
					}
				}
				
				// activer la pop-up d'activation
				$("#modalProgrammeSanteActive").on('hidden.bs.modal', function (e) {
					 $scope.$apply(function(){
				          $location.path("/accueil");
				      });
				}).modal('show');
				
				// maj contacts médical
				AccueilService.getContactMedicals(null, true, null).then(function(response) {
					CommunService.contactMedicals = response.data;
				});
			});
		}
		
		/**
		 * Appeler lors du changement de selection d'une donnée de saisie
		 */
		$scope.changeDonneeSaisies = function(){
			// réinitialisation des champs de saisies valeurs
			$scope.champSaisieValeurs = [];
			// dans le cas ou il s'agit pas d'un champsaisie personnalisé
			// si il s'agit d'un champ perso cette fonction sera appelé dans le template (html) du champ de saisie
			if($scope.champSaisie.entreeType != "PERSONNALISE"){
				CommunService.createChampSaisieValeur($scope.champSaisie.id, $scope.champSaisie.code, $scope.champSaisie.entreeType, $scope.champSaisieValeurs);
			}
		}
		
		/**
		 * Validation et appel webservices pour la creation des constantes
		 */
		$scope.creerChampSaisieValeurDonneesMedicales = function(moment, dateSaisie){
			// date de mesure
			var dateMesure = new Date(); // maintenant
			if(moment === 'matin'){
				dateMesure.setHours(8);
			}
			else if(moment === 'apres-midi'){
				dateMesure.setHours(14);
			}
			else if(moment === 'autre'){
				dateMesure = dateSaisie;
			}
			
			var champSaisieValeurs = CommunService.getChampSaisieValeurListe($scope.champSaisieValeurs, dateMesure);
			$scope.formInvalide = (champSaisieValeurs == null);
			// si le formulaire est valide
			if(!$scope.formInvalide){
				programmeSanteService.createChampSaisieValeur(champSaisieValeurs).then(function(response){
					$scope.champSaisie = null;
					$scope.champSaisieValeurs = [];
					$scope.afficherModal("Mes données médicales", "Les données médicales ont été enregistrées.");
				});
			}
		}
		
		/**
		 * Affichage pop-up tableau de bord
		 */
		$scope.affichProgrammeSantePopPup = function(programmeSanteAbonnement){
			$scope.programmeSanteAbonnementSelect = programmeSanteAbonnement;
			$scope.programmeSanteSelect = programmeSanteAbonnement.programmeSante;
			
			// reference egalement dans parentController
			CommunService.programmeSanteId = programmeSanteAbonnement.programmeSante.id;
			
			$("#modalProgrammeSante").modal('show');
		}
		
		$scope.getQuestionnaireImc = function() {
			var imc = 0;
			var taille = CommunService.valeurChampSaisie["TAILLE"];
			var poids = CommunService.valeurChampSaisie["POIDS"];
			if (taille && taille.valeur && poids && poids.valeur) {
				imc = parseFloat(poids.valeur / (Math.pow(taille.valeur / 100, 2))).toFixed(2);
			}
			return imc;
		}
	
	
		/**
		 * Indique si le questionnaire est renseigné
		 */
		$scope.isQuestionnaireFilled = function() {
			var isQuestionnaireFilled = true;
			$(".questionnaire_champ").each(function(index) {
				var value;
				if ($(this).is(":radio") || $(this).is(":checkbox")) {
					$(".questionnaire_champ[name=" + $(this).attr("name") + "]:checked").each(function(index2) {
						value = $(this).attr("value");
					});
				} else {
					value = $(this).val();
				}
				if (!value || value.length === 0 || value === "? undefined:undefined ?" || value =="?") {
					isQuestionnaireFilled = false;
				}
			});
			return isQuestionnaireFilled;
		}
	
		MedecinGeneralisteService.init($scope).then(function(data){
			$scope.typeaheadObject = data;
			// données affichées (mises à jour avec typeaheadObject seulement après validation de la popup)
			$scope.medecinGeneraliste = data.medecinGeneraliste;
			$scope.autorisation = data.autorisation;
		});
		
		
		/**
		 * Récupération des programmes de santé
		 */
		var init = function() {
			// initialisation des champs de saisies pour le questionnaire
			$scope.champSaisieValeurs = [];
	
			programmeSanteService.getProgrammeDocuments().then(function(response) {
				CommunService.programmeSanteDocuments = response.data;
			});
			
		}
		init();
	}

	programmeSanteController.$inject = ['CommunService', '$filter', '$location', '$scope', '$timeout', '$http', 'AccueilService', 'programmeSanteService' ,'MedecinGeneralisteService', 'sessionService'];
	
	angular
	  .module('clientApp')
	  .controller('ProgrammeSanteController', programmeSanteController);

})(angular);