(function(angular){

	/**
	 * Controller des champs de saisies
	 * @param $controller
	 * @param $http
	 * @param $scope
	 * @param $timeout
	 * @param $window
	 * @param CommunService
	 * @param chargerCourbe
	 * @param CourbeService
	 * @returns
	 */
	function champSaisieController($http, $scope, $timeout, $window, CommunService, chargerCourbe, CourbeService) {
		$scope.Commun = CommunService;
	
		Highcharts.setOptions({
			global: {
				useUTC: false
			},
			lang: {
				decimalPoint: ",",
				months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",  "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
				resetZoom : "Rétablir le niveau de zoom par défaut",
				resetZoomTitle : "Rétablir le niveau de zoom à 100%"
			}
		});
	
		// initialisation des listes de champ saisie à partir du résultat de "chargerCourbe"
		// qui récupère les données en base avant instanciation du controller
		CourbeService.chargerCourbe(CommunService, chargerCourbe.data);
	
		// afficher les courbes pour chaque champ saisie
		// attention, listeChampSaisieGraphique et listeCourbesSeries doivent être prêts -> ChargerCourbe doit être fini
		$scope.afficherSortie = function(champSaisieId) {
			$(document).ready(function() {
				CourbeService.afficherSortie(champSaisieId, CommunService);
			});
		};
	
		$scope.setVueHistorique = function(vueHistorique) {
			$scope.vueHistorique = vueHistorique;
			if (!vueHistorique) {
				if ($scope.isRefreshCourbes) {
					$window.location.reload();
				}
				$timeout(function() {
					jQuery(document).resize(); 
				}, 1);
			}
		};
	
		// TODO arreter datePicker et de manière de faire..
		$scope.initDatetimePicker = function() {
			$.datetimepicker.setLocale("fr");
			$timeout(function() {
				for (var i = 0; i < CommunService.listeChampSaisieValeur.length; i++) {
					valeur = CommunService.listeChampSaisieValeur[i];
					$("#datetimepicker" + valeur.id).datetimepicker({
//						format: "d/m/Y H:i",
						step: 30
					});
				}
			}, 1);
		};
	
		$scope.confirmationModification = function(champSaisieValeur) {
			$scope.champSaisieValeurAModifier = champSaisieValeur;
			$('#modalChampSaisieModification').modal('show');
		};
	
		$scope.modifierChampSaisieValeur = function(confirmation) {
			$('#modalChampSaisieModification').modal('hide');
			if (confirmation === true) {
				$scope.champSaisieValeurAModifier.ancienneDate = $scope.champSaisieValeurAModifier.date;
				$http({method: "PUT", url: "mobile/programmesante/champsaisie/valeur", data: $.param({"id": $scope.champSaisieValeurAModifier.id,"date": $scope.champSaisieValeurAModifier.date}),
					headers: {"Content-Type": "application/x-www-form-urlencoded"}
				});
			} else {
				$scope.champSaisieValeurAModifier.date = $scope.champSaisieValeurAModifier.ancienneDate;
			}
		};
	
		$scope.confirmationSuppression = function(champSaisieValeur) {
			$scope.champSaisieValeurASupprimer = champSaisieValeur;
			$('#modalChampSaisieSuppression').modal('show');
		};
	
		$scope.supprimerChampSaisieValeur = function(confirmation) {
			$('#modalChampSaisieSuppression').modal('hide');
			if (confirmation === true) {
				$http({method: "DELETE", url: "mobile/programmesante/champsaisie/valeur", params: { id: $scope.champSaisieValeurASupprimer.id }}).then(function(response) {
					CommunService.listeChampSaisieValeur = CommunService.listeChampSaisieValeur.filter(function(valeur){
						return valeur.id != $scope.champSaisieValeurASupprimer.id;
					});
					$scope.isRefreshCourbes = true;
					$scope.champSaisieValeurASupprimer = undefined;
				});
			}
		};
	}

	champSaisieController.$inject = ['$http', '$scope', '$timeout', '$window', 'CommunService', 'chargerCourbe', 'CourbeService'];
	
	angular
	  .module('clientApp')
	  .controller('ChampSaisieController', champSaisieController);

})(angular);