angular.module("clientApp").factory("AccueilService", ['$http', 'CommunService', function($http, CommunService) {
	enrichissementParam = function(params) {
		if (!params) {
			params = {};
		}
		params.navigateur = {
//			CookieEnabled : navigator.cookieEnabled,
//			Language : navigator.language,
//			Resolution : window.screen.width + " x " + window.screen.height,
//			UserAgent : navigator.userAgent
			userAgent : navigator.userAgent
		}
		return params;
	};

	return {
		creerAvis : function(avis) {
			avis.userAgent = navigator.userAgent;
			return $http.post("mobile/creation-avis-site", JSON.stringify(avis));
		},
		creerProbleme : function(avis) {
			avis.userAgent = navigator.userAgent;
			avis.probleme = true;
			return $http.post("mobile/creation-avis-site", JSON.stringify(avis));
		},
		getAllContactMedicals: function(actifContact, actifMessagerie, actifAlarme) {
			return $http.get("mobile/programmesante/contact/all", { params : {
				actifContact: actifContact,
				actifMessagerie: actifMessagerie,
				actifAlarme: actifAlarme
				}
			})
		},
		getUrlTeleConsultation: function() {
			return $http({
				method: "GET",
				url: "mobile/patient/new/url-tele-consultation"
			});
		},
		setRappelActivite : function(rappelActivite) {
			$http({
				method: "POST",
				url: "mobile/setRappelActivite",
				data: $.param({
					"rappelActivite": rappelActivite
				}),
				headers: {"Content-Type": "application/x-www-form-urlencoded"}
			});
		},
		getPatientByToken : function(token) {
			return $http({
				method: "GET",
				url: "mobile/patient-connecte",
				headers: {"Authorization": "Bearer " + token}
			});
		},
	}
}]);