(function(angular){
		
	/**
	 *  Filter de date au format string
	 *  @param input est un string au format date
	 */
	function dateFilter($filter){
		return function(input, format) {
			var dateFormat;
			var date = new Date(input);
			if(format){
				dateFormat = $filter('date')(date, format);
			}
			else{
				dateFormat = $filter('date')(date, "dd/MM/yyyy");
			}
			return dateFormat;
		};
				  
	}
		
	dateFilter.$inject = ['$filter'];
		
	angular.module('clientApp')
		.filter('dateFilter', dateFilter);

})(angular);