(function(angular){
	
	/**
	 * Directive pour utiliser
	 */
	function intlTelDirective(OPTIONS_TELEPHONE){
		
		return{
			replace:true,
			restrict:'E',
			require: 'ngModel',
			template : '<input type="text" >',
			scope:{ telephone : '@'},
			  
			link: function(scope, element, attrs, ngModel) {
				// initialisation
				$(element).val(scope.telephone);
				
				var read = function() {
					var inputValue = element.val();
					ngModel.$setViewValue(inputValue);
				}      
				element.intlTelInput(OPTIONS_TELEPHONE);
				element.on('focus blur keyup change', function() {
					scope.$apply(read);
				});
				read();
			}
		}
	}
		
	intlTelDirective.$inject = ['OPTIONS_TELEPHONE'];
		
	angular.module('clientApp')
		.directive('intlTelDirective', intlTelDirective);

})(angular);