angular.module("clientApp").factory("PersonneAPrevenirService", ['$http', 'sessionService', '$rootScope', 'EVENT', function($http, sessionService, $rootScope, EVENT) {

	/**
	 * Notification lorsque les personnes à prévenir sont modifiées
	 */
	function notifiePersonnesAPrevenir(personnesAPrevenirs){
		$rootScope.$broadcast(EVENT.majPersonneAPrevenir, personnesAPrevenirs);
	}
	
	/**Ajoute en base une personne à prevenir*/
	var dialogFichePersoPrevenirSubmit = function(form, scope){
		$http.post("mobile/personne-prevenir", JSON.stringify(scope.personneAPrevenir)).then(function(response) {
			scope.personneAPrevenirObject.personnesAPrevenir = response.data;
			// modifier l'objet patient en session
			sessionService.getUser().personneAPrevenirs = response.data;
			// on notifie 
			notifiePersonnesAPrevenir(sessionService.getUser().personneAPrevenirs);
		});
	};
	
	var ajouterPersonneAPrevenir = function(scope) {
		scope.personneAPrevenir = {};
		// Par défaut on prévient par SMS
		scope.personneAPrevenir.prevenirSms = true;
	};
	
	var modifierPersonneAPrevenir = function(personne, scope) {
		scope.personneAPrevenir = personne;
	};

	var supprimerPersonneAPrevenir = function(personne, scope) {
		if (confirm("Souhaitez-vous ne plus prévenir " + personne.prenom + " " + personne.nom + " ?")) {
			$http({method: 'DELETE', url:'mobile/personne-prevenir?personneId=' + personne.id}).then(function(response){
				// on efface la personne supprimee
				scope.personneAPrevenirObject.personnesAPrevenir.splice( scope.personneAPrevenirObject.personnesAPrevenir.indexOf(personne), 1 );
				sessionService.getUser().personneAPrevenirs = scope.personneAPrevenirObject.personnesAPrevenir;
				// on notifie 
				notifiePersonnesAPrevenir(sessionService.getUser().personneAPrevenirs);
			});
		}
	};
	
	var getPersonnesAPrevenir = function(){
		return $http.get("mobile/personne-prevenir");
	}
	
	return {	
		// TODO : à changer : horrible
		init : function(scope) {
			return getPersonnesAPrevenir().then(function(response) {
				return {
					personnesAPrevenir : response.data,
					ajouterPersonneAPrevenir : function(){
						ajouterPersonneAPrevenir(scope);
					},
					modifierPersonneAPrevenir : function(personne){
						modifierPersonneAPrevenir(personne, scope);
					},
					supprimerPersonneAPrevenir : function(personne){
						supprimerPersonneAPrevenir(personne, scope);
					},
					dialogFichePersoPrevenirSubmit : function(form){
						dialogFichePersoPrevenirSubmit(form, scope);
					}
				};
		 	});
		}
	}
}]);