angular.module("clientApp").factory("CommunService", ['sessionService', '$anchorScroll', '$location',  function (sessionService, $anchorScroll, $location) {
	return {
		programmeSante: [],
		addDays : function(date, amount) {
			var tzOff = date.getTimezoneOffset() * 60 * 1000,
				t = date.getTime(),
				d = new Date(),
				tzOff2;

			t += (1000 * 60 * 60 * 24) * amount;
			d.setTime(t);

			tzOff2 = d.getTimezoneOffset() * 60 * 1000;
			if (tzOff != tzOff2) {
				var diff = tzOff2 - tzOff;
				t += diff;
				d.setTime(t);
			}

			return d;
		},
		// affichage des popups de l'espace patient
		afficherPopup : function (nomPopup) {
			var dialogue = $("#" + nomPopup);
			dialogue.removeClass("hide");
			var fenetre = $(window);

			dialogue.dialog({autoOpen: false, closeText: "", modal: true, maxHeight: fenetre.height() * 0.9, width: "auto", resizable: false,
				close: function (event, ui) {
					dialogue.addClass("hide");
					dialogue.dialog("destroy");
				},
				create: function (event, ui) {
					$(this).css("maxWidth", fenetre.width() * 0.9);
				}
			});
			setTimeout(function() {
				dialogue.dialog("open");
				dialogue.scrollTop("0");
			}, 50);
			$(".ui-dialog-titlebar").css({
				"background-color": "#82c029",
				"font-size": "22px",
				"font-weight": "normal"
			});
			$(".ui-dialog-titlebar-close").css({
				"background-color": "initial",
				"background-image": "url(images/btn_fermer.png)",
				"border": "0",
				"border-radius": "0",
				"height": "26px",
				"margin-top": "-13px",
				"width": "26px"
			});
			$(".ui-dialog-content").css({
				"background-color": "white",
				"line-height": "1.5",
				"padding": "20px 40px",
				"text-align": "justify"
			});
			$(".ui-icon").css({
				"display": "none"
			});
		},
		// fermeture des popups de l'espace patient
		fermerPopup : function(nomPopup) {
			var dialogue = $("#" + nomPopup);
			dialogue.addClass("hide");
			dialogue.dialog("destroy");
		},
		getUrlChampsaisie : function(champsaisie) {
			var url = "";
			if (champsaisie) {
				url = "pages/programme-sante/ChampSaisie/entree/" + champsaisie;
			}
			return url;
		},
		scrollTo : function(id) {
			var old = $location.hash();
			$location.hash(id);
			$anchorScroll();
			$location.hash(old);
		},
		// *** Functions partagé pour l'écran accueil et la saisie des constantes et le questionnaire des programmes de santé ***
		// utilisation pour la création des champs de saisies
		construitChampSaisieValeur : function(idChampSaisie, codeChampSaisie, obligatoire, type){
			var champSaisieValeur = {};
			champSaisieValeur.champSaisie = {};
			champSaisieValeur.champSaisie.id = idChampSaisie;
			champSaisieValeur.champSaisie.code = codeChampSaisie;
			champSaisieValeur.origine = "PATIENT";
			champSaisieValeur.patientId = sessionService.getUser().patientId;
			champSaisieValeur.obligatoire = obligatoire;
			champSaisieValeur.type = type;
			return champSaisieValeur;
		}, 
		// le parametre codeDifferentPourMemeChamp a été rajouté pour le champsasie customiser : champsaisie_pression_3
		// car il y a plusieurs fois le même champ de saisie dans le formulaire
		createChampSaisieValeur : function(idChampSaisie, codeChampSaisie, type, champSaisieValeurs, codeDifferentPourMemeChamp){
			var champSaisieValeur = this.construitChampSaisieValeur(idChampSaisie, codeChampSaisie);
			if( type === 'MULTI_LISTE'){
				champSaisieValeur.valeur = [];
			}
			champSaisieValeurs[!codeDifferentPourMemeChamp ? codeChampSaisie : codeDifferentPourMemeChamp] = champSaisieValeur;
		},
		// Création des champs de saisie en prenant en compte un flag obligatoire ou non
		createChampSaisieObligatoireValeur : function(idChampSaisie, codeChampSaisie, type, champSaisieValeurs, codeDifferentPourMemeChamp, obligatoire){
			var champSaisieValeur = this.construitChampSaisieValeur(idChampSaisie, codeChampSaisie, obligatoire, type);
			if( type === 'MULTI_LISTE'){
				champSaisieValeur.valeur = [];
			}
			champSaisieValeurs[!codeDifferentPourMemeChamp ? codeChampSaisie : codeDifferentPourMemeChamp] = champSaisieValeur;
		},
		// Ajout d'une valeur à un champ de saisie 'MULTI_LISTE'
		ajoutChampSaisieValeurListe : function(codeChampSaisie, valeur, champSaisieValeurs){
			// ajout de la valeur à la liste
			var indexValeur = champSaisieValeurs[codeChampSaisie].valeur.indexOf(valeur);
			if( indexValeur == -1){
				champSaisieValeurs[codeChampSaisie].valeur.push(valeur);
			}
			// sinon retrait
			else{
				champSaisieValeurs[codeChampSaisie].valeur.splice(indexValeur, 1);
			}
		},
		// creation de la liste de champsaisie valeur à envoyer
		getChampSaisieValeurListe : function(champSaisieValeurs, dateMesure, etapepatientId){
			console.log(JSON.stringify(champSaisieValeurs));
			// champ de saisies valeurs
			var champSaisieValeurListe =[];
			for(var name in champSaisieValeurs){
				var champSaisieValeur = champSaisieValeurs[name];
				if (etapepatientId) {
					champSaisieValeur.etapepatientId = etapepatientId;
				}
				
				// Cas particulier pour les booléans
				if (champSaisieValeur.type == 'BOOLEEN') {
					champSaisieValeur.valeur = champSaisieValeur.valeur ? "OUI" : "NON";
				}

				// problème de validation des données
				if(champSaisieValeur.obligatoire && !champSaisieValeur.valeur || (Array.isArray(champSaisieValeur.valeur) && !champSaisieValeur.valeur.length)){
					return null;
				} 
				// dans le cas d'un champ de saisie de type multi liste
				else if(Array.isArray(champSaisieValeur.valeur)){
					for(var i= 0; i < champSaisieValeur.valeur.length; i++){
						var champSaisieValeurElement = this.construitChampSaisieValeur(champSaisieValeur.champSaisie.id, champSaisieValeur.champSaisie.code);
						champSaisieValeurElement.valeur = champSaisieValeur.valeur[i];
						champSaisieValeurElement.dateMesure = dateMesure;
						champSaisieValeurListe.push(champSaisieValeurElement);
					}
				}
				else{
					if(!champSaisieValeur.dateMesure){
						champSaisieValeur.dateMesure = dateMesure;
					}
					// dans le cas champ pression 3 : champsaisie_pression_3.html
					else{
						champSaisieValeur.dateMesure = new Date(champSaisieValeur.dateMesure);
					}
					champSaisieValeurListe.push(champSaisieValeur);
				}
			}
			return champSaisieValeurListe;
		}
		// *** ***
	};
}]);