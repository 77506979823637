(function(angular){
	function parametersService($http, $q ){
		
		const module = 'EspPat';
		
		/* quand l'id en base est connue */
		this.getParamById = function(id){
			var defered = $q.defer();
			return $http.get("mobile/get-parameter-byId/"+id).then(function (response) {
				console.log("getParamById: " + id + " - " + response.data.key );
				defered.resolve(response.data);
			});
			return defered.promise;
		}
		
		/* raccourci : le back rajoutera les valeurs par défaut pour la langue et id d'application */
		this.getParamByKey = function(key){
			var defered = $q.defer();
			return $http.get("mobile/get-parameter-key/"+key).then(function (response) {
				defered.resolve(response.data);
			});
			return defered.promise;
		}
		
		/* Le cas nominal : demander une clé avec un Id de langage, le back de l'appli rajoutera l'id de l'application courante */
		this.getParamByKeyLang = function(key, lang){
			var defered = $q.defer();
			$http.get("mobile/get-parameter-key/"+key+"/"+lang).then(function (response) {
				defered.resolve(response.data);
			});
			return defered.promise;
		}
		
		/* pour afficher tous les parametres, ne devrait pas être utile dans l'appli */ 
		this.getParams = function(){
			var deferred = $q.defer();
			$http.get("mobile/get-parameter-keys/")
				.success(function(data) {
					  deferred.resolve({data: data});
					  $log.info('sucess')
		       }).error(function(msg, code) {
		          deferred.reject(msg);
		          $log.error(msg, code);
		       });
			return deferred.promise;
		}
		

		
	}

	parametersService.$inject = ['$http', '$q'];
	angular
	    .module('clientApp')
	    .service('parametersService', parametersService);
	
})(angular);
