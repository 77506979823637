/**
 * Service d'affichage des champ saisie valeur sous forme de courbes
 * Commun à tous les espaces: si modification, reporter dans les fichiers dupliqués dans les autres espaces
 */
angular.module("clientApp").factory("CourbeService", ['$http', '$filter',  function($http, $filter) {

	var formatDateChampSaisieHistorique = function(date) {
		return $filter("date")(date, "dd/MM/yyyy HH:mm");
	};

	return {
		afficherSortie : function (champSaisieId, scope) {
			var listeChampSaisie = scope.listeChampSaisieGraphique;
			for (var i = 0; i < listeChampSaisie.length; i++) {
				champSaisie = listeChampSaisie[i];
				if (champSaisie.id === champSaisieId && champSaisie.sortieType === 'GRAPHIQUE') {
					if(!$("#chartContainer"+ champSaisieId).length) {
						$("#chartContainer").append("<div class=\"col-xs-12 col-md-6 col-lg-4\" style=\"padding:0\" id=\"chartContainer" + champSaisieId + "\"></div>");
					}
					new Highcharts.Chart({
						chart: {
							backgroundColor: "#FFD",
							borderColor: "#888",
							borderWidth: 1,
							plotBackgroundColor: "#EEF",
							renderTo: "chartContainer" + champSaisieId,
							type: "spline",
							zoomType: "xy"
						},
						legend: {
							align: "center",
							enabled: (scope.listeCourbesSeries[champSaisieId].length > 1),
							layout: "horizontal",
							verticalAlign: "bottom"
						},
						plotOptions: {
							line: {
								dataLabels: {
									enabled: true
								}
							}
						},
						title: {
							text: scope.listeCourbesTitre[champSaisieId]
						},
						tooltip: {
							headerFormat: "<span style='font-size: 10px'>Le {point.key}</span><br/>",
							valueSuffix: " " + scope.listeCourbesUnite[champSaisieId],
							xDateFormat: "%e %B %Y à %H:%M:%S"
						},
						xAxis: {
							type: "datetime",
							dateTimeLabelFormats: {
								millisecond: "%H:%M:%S",
								day: "%e %B",
								week: "%e %B",
								month: "%b %Y"
							},
							labels: {
								staggerLines: 1,
								style: {
									color: "#000000",
									fontSize: "14px"
								}
							},
							tickWidth: 1
						},
						yAxis: {
							labels: {
								style: {
									color: "#000000",
									fontSize: "15px"
								}
							},
							title: {
								text: null
							}
						},
						series: scope.listeCourbesSeries[champSaisieId]
					});
				}
			}
		},
		
		chargerCourbe : function(scope, data) {
			listeChampSaisieValeurJson = data;
			listeChampSaisieGraphique = new Array();
			listeChampSaisieSortie = new Array();
			listeChampSaisieValeur = new Array();
			listeCourbesSeries = new Array();
			listeCourbesTitre = new Array();
			listeCourbesUnite = new Array();
			listeId = new Array();
			var champSaisieId = -1;
			var champSaisieCode = undefined;
			var sortieNumGraphique = -1;

			// trier par champSaisie pour remplir les courbes
			listeChampSaisieValeurJson = $filter("orderBy")(listeChampSaisieValeurJson, ['champSaisie.sortieNumGraphique', 'champSaisie.id', 'dateMesure'])
			for (var i = 0 ; i < listeChampSaisieValeurJson.length ; i++) {
				champSaisieValeur = listeChampSaisieValeurJson[i];
				champSaisie = champSaisieValeur.champSaisie;
				if (!listeId[champSaisie.id] && champSaisie.sortieType === 'GRAPHIQUE' && sortieNumGraphique !== champSaisie.sortieNumGraphique) {
					listeChampSaisieGraphique.push(champSaisie);
					listeId[champSaisie.id] = true;
				}
				if (!listeId[champSaisie.id] && champSaisie.sortieType === 'PERSONNALISE') {
					listeChampSaisieSortie.push(champSaisie);
					listeId[champSaisie.id] = true;
				}

				listeChampSaisieValeur.push({
					id: champSaisieValeur.id,
					champSaisieId: champSaisie.id,
					champSaisieCode: champSaisie.code,
					mesure: (champSaisie.sortieType !== 'PERSONNALISE' && champSaisie.sortieNom) ? champSaisie.sortieNom : champSaisie.libelle,
					date: formatDateChampSaisieHistorique(champSaisieValeur.dateMesure),
					ancienneDate: formatDateChampSaisieHistorique(champSaisieValeur.dateMesure),
					note: champSaisieValeur.note,
					timestamp: champSaisieValeur.dateMesure,
					sortieNumGraphique: champSaisie.sortieNumGraphique,
					valeurOriginale: champSaisieValeur.valeur,
					origine: champSaisieValeur.origine,
					valeur: (champSaisie.typeChampSaisie === "BOOLEEN" ? (champSaisieValeur.valeur === "true" ? "Oui" : "Non") : champSaisieValeur.valeur + (champSaisie.unite ? " " + champSaisie.unite : "")),
					categorie: champSaisie.champSaisieCategorie.libelle
				});
				if (champSaisie.sortieType === 'GRAPHIQUE') {
					if (sortieNumGraphique !== champSaisie.sortieNumGraphique) {
						series = new Array();
						serie = new Array();
					}

					serieArray = new Array();
					serieArray.push(new Date(champSaisieValeur.dateMesure));
					serieArray.push(parseFloat(champSaisieValeur.valeur));

					// 2 séries différentes si ce sont des champs saisie différents (sauf si ils ont le même code ex: pouls)
					if (champSaisie.id !== champSaisieId && (!champSaisie.code || !champSaisieCode || champSaisie.code != champSaisieCode)) {
						serie = new Array();
						series.push({name: (champSaisie.sortieNom ? champSaisie.sortieNom : champSaisie.libelle), data: serie});
					}
					serie.push(serieArray);
					// sort serie values [date,value] by date
					serie.sort(function(a,b){
						if(a[0] < b[0]) {
							return -1;
						} else if(a[0] > b[0]) {
							return 1;
						}
						return 0;
					});
					if (sortieNumGraphique !== champSaisie.sortieNumGraphique) {
						listeCourbesSeries[champSaisie.id] = series;
						listeCourbesTitre[champSaisie.id] = (champSaisie.entreeType !== 'PERSONNALISE' && champSaisie.entreeNom) ? champSaisie.entreeNom : ((champSaisie.sortieNom) ? (champSaisie.sortieNom + (champSaisie.unite ? " (" + champSaisie.unite + ")" : "")) : (champSaisie.libelle + (champSaisie.unite ? " (" + champSaisie.unite + ")" : "")));
						listeCourbesUnite[champSaisie.id] = (champSaisie.unite ? champSaisie.unite : "");
					}

					champSaisieId = champSaisie.id;
					champSaisieCode = champSaisie.code;
					sortieNumGraphique = champSaisie.sortieNumGraphique;
				}
			}
			// valeurs à afficher dans l'historique
			scope.listeChampSaisieValeur = $filter("orderBy")(listeChampSaisieValeur, ["-timestamp", "sortieNumGraphique" ,"champSaisieId"]);
			// séries à afficher dans les courbes
			scope.listeCourbesSeries = listeCourbesSeries;
			// titres à afficher dans les courbes
			scope.listeCourbesTitre = listeCourbesTitre;
			// unites à afficher dans les courbes
			scope.listeCourbesUnite = listeCourbesUnite;
			// valeurs à afficher dans les courbes
			scope.listeChampSaisieGraphique = listeChampSaisieGraphique;
			// valeurs à afficher dans les sorties
			scope.listeChampSaisieSortie = listeChampSaisieSortie;
		},
		
		// récupération des champ saisie valeurs dans la BD pour affichage courbe ou historique
		promiseChargerCourbe : function(){
			return $http.get("mobile/programmesante/champsaisie/valeur");
		}
	}
}]);