(function(angular){

	function hospitController(	$scope, $http, $window, sessionService, CommunService, HospitService,
								PersonneAPrevenirService, patientService, documentService, $routeParams,
								$filter, LIENS_PATIENT, PIECES_JOINTES, ETAPES_PREADMISSION,
								FileUploader, EVENT, $location, $sce) {

		$scope.Commun = CommunService;

		$scope.token = sessionService.getAccessToken();

		// ----------------- PREADMISSION ------------------
		$scope.closeModalChambreParticuliere =  function(e) {
			$('#modalChambreParticuliere').modal('hide');
			$('#modalChambreParticuliere2').modal('show');
		}
		
		$scope.openMessagerie =  function() {
			$('#modalChambreParticuliere').modal('hide');
			$('.modal-backdrop').remove()
			$location.path("messages/messagesClinique");
		}

		$scope.closeModalChambreParticuliere2 =  function(e) {
			$('#modalChambreParticuliere2').modal('hide');
		}

		$scope.payerChambreInd =  function(e) {
			// Récupération des infos pour procéder au paiement e-transaction
			patientService.getInfoPaiementChambreIndividuelle($scope.sejourEnCours.sejourId).then(function(response){
				$scope.urlPaiementChambreIndividuelle = $sce.trustAsResourceUrl(response.data.pbxAction);
				$scope.paiementChambre = response.data;

				// Submit aprés 100ms le temps que urlPaiementChambreIndividuelle soit bindé ...
				window.setTimeout($scope.submitForm, 100);
			});
		}

		// Submit du formulaire
		$scope.submitForm = function() {
			document.getElementById("chambreIndForm").submit();
		}

		$scope.avisSubmit = function() {
			// ajout du séjourId à l'avis
			$scope.avis.sejourId = CommunService.sejourId;
			HospitService.creerAvis($scope.avis).then(function(data) {
				$('#modalAvisInterne').on('hidden.bs.modal', function (e) {
					$('#modalValidationPreadmission').on('hidden.bs.modal', function (e) {
						 $scope.$apply(function(){
							 $location.path("accueil");
					      });
					  }).modal('show');
				  }).modal('hide');

				$scope.avis.note = undefined;
				$scope.avis.commentaire = undefined;
			});
		}

		$scope.finPreadmission = function(){
			$('#modalValidationPreadmission').modal('hide');
		}

		$scope.redirectionAccueil = function(){
			$location.path("accueil");
		}

		// le patient rensigne les pharamacies de référence
		$scope.chercherPharmacie = function(pharmacie) {

				return $http.post("mobile/pharmacie/recherche-pharmacie" ,$scope.pharmacie).then(function(response) {
					$scope.pharmaciesResultatObjet = response.data;
					if ($scope.pharmaciesResultatObjet.length ==  0){
						$scope.messageRecherchePharmacie = "Votre recherche ne correspond à aucune pharmacie existante!";
						$scope.pharmaciesResultatObjet = null;
					} else {
						$scope.messageRecherchePharmacie = undefined;
					}
				});
		}
		$scope.afficherPharmacieModal = function() {
			$('#modalPharmacie').modal('show');
		}
		$scope.rajouterPharmacie = function (pharmacie) {
			pharmacie.reference = true;
			$scope.patientPharmacieSelect = pharmacie;
			$scope.pharmacie= {};
			$scope.messageRecherchePharmacie = undefined;
		}

		$scope.ajoutPharmacie  = function () {
			$scope.messageRecherchePharmacie = undefined;
			var patientPharmaciesList =$filter('filter')( sessionService.getUser().pharmacies, { pharmacie: {id:$scope.patientPharmacieSelect.id }}, true);
			if (!patientPharmaciesList.length ){
				$http.post("mobile/pharmacie/ajout-pharmacie",
						JSON.stringify($scope.patientPharmacieSelect)).then(function(response) {
					var patientPharmacie = response.data
					sessionService.getUser().pharmacies.push(patientPharmacie);
					sessionService.setUser(sessionService.getUser());
					$scope.pharmaciesResultatObjet = null;
					$scope.patientPharmacieSelect = null;
					CommunService.fermerPopup("popupPharmacie");
				});
			} else {
				$scope.messageRecherchePharmacie = "Cette pharmacie existe déja";
			}
		}

		$scope.annulerPharmacie = function () {
			$scope.pharmaciesResultatObjet = null;
			$scope.messageRecherchePharmacie = undefined;
			$scope.patientPharmacieSelect = null;
			CommunService.fermerPopup("popupPharmacie");

		}

		$scope.supprimerPharmacie = function(patientPharmacie){
			var index = sessionService.getUser().pharmacies.indexOf(patientPharmacie);
			if (index > -1) {
				return $http.delete("mobile/pharmacie/delete?id=" + patientPharmacie.id).then(function(response) {
					sessionService.getUser().pharmacies.splice(index ,1);
					sessionService.setUser(sessionService.getUser());
				});
			}

		}

		// on ne va chercher les personnes à prévenir que si le patient ouvre la popup
		$scope.chargerPersonneAPrevenir = function() {
			PersonneAPrevenirService.init($scope).then(function(data){
				$scope.personneAPrevenirObject = data;
			});
		}

		$scope.afficherPersonneAPrevenir = function() {
			$('#modalPersonneAPrevenir').modal('show');
		}

		$scope.enregistrerPersonneAPrevenir = function(form){
			if(form.$valid){
				$scope.personneAPrevenirObject.dialogFichePersoPrevenirSubmit(form);
				$('#modalPersonneAPrevenir').modal('hide');
			}
			else{
				form.submitted = true;
			}
		}

		$scope.cliqueChambreParticuliere = function(value){

			// Si chambre individuelle et pas de numéro de séjour il faut en créer un
			if(!$scope.sejourEnCours.sejourId && value) {
				patientService.updatePatientPreadmission($scope.patient).then(function(response){
					// maj de la session
					sessionService.setUser(response.data);

					// maj de l'objet BDD
					$scope.patientBdd = angular.copy($scope.patient);

					$scope.sejourEnCours.sejourId = sessionService.getUser().sejours[0].sejourId;
					sessionService.setProchainSejourId(sessionService.getUser().sejours[0].sejourId);

					// Si choix de la chambre individuelle, affichage de la popup
					if(value){
						$('#modalChambreParticuliere').modal('show');
					}
				});
			} else {
				// Si choix de la chambre individuelle, affichage de la popup
				if(value){
					$('#modalChambreParticuliere').modal('show');
				}
			}
		}

		// obliger d'utiliser wrapper en attendant de regarder la doc du composant
		// ui-select
				$scope.patientPharmacieSelect = {};
		// $scope.patientPharmacieSelect.reference = true;
				$scope.pharmacie= {};
		// $scope.pharmacie.reference = true;
		// $scope.patientPharmacieSelect.reference = true;
				$scope.pharmaciesResultatObjet = null;

		// ---------------------------------------
		// --------- init pre-admission ----------
		// ---------------------------------------
		$scope.initPreAdmission = function() {

			// initialisation du patient
			$scope.patient = {};
			$scope.patient.patientId = sessionService.getUser().patientId;
			$scope.patient.dejaHospitalise = sessionService.getUser().dejaHospitalise;
			$scope.patient.civilite = sessionService.getUser().civilite;
			$scope.patient.nomJeuneFille = sessionService.getUser().nomJeuneFille;
			$scope.patient.prenom = sessionService.getUser().prenom;
			$scope.patient.dateNaissance = sessionService.getUser().dateNaissance;
			$scope.patient.mail = sessionService.getUser().mail;
			$scope.patient.sejourPreAdmission = $scope.sejourEnCours;
			$scope.patient.personneAPrevenirs = sessionService.getUser().personneAPrevenirs;

			// copie de l'objet (correspond aux données BDD)
			// utilisé pour la validation
			$scope.patientBdd = angular.copy($scope.patient);

			// initialisation des étapes
			// si dossier validé ou complet -> affichage de l'étape 8 directement
			if($routeParams.etape && $routeParams.etape > 0 && $routeParams.etape < 9){
				$scope.etapePreadmission = parseInt($routeParams.etape);
			}else{
				$scope.etapePreadmission = (!$scope.sejourEnCours || !$scope.sejourEnCours.etapePreadmission) ? 1 :  $scope.sejourEnCours.etapePreadmission;
			}

			// initialisation du séjour courant
			$scope.openDatePicker = function() {
				$scope.popupDatePicker.opened = true
			}
			$scope.popupDatePicker = {opened: false}

			//propriétés communes entre séjour / patient
			$scope.sejourEnCours.nom = !$scope.sejourEnCours.nom ? sessionService.getUser().nom : $scope.sejourEnCours.nom;
			$scope.sejourEnCours.adresse = !$scope.sejourEnCours.adresse ? sessionService.getUser().adresse : $scope.sejourEnCours.adresse;
			$scope.sejourEnCours.codePostal = !$scope.sejourEnCours.codePostal ?sessionService.getUser().codePostal : $scope.sejourEnCours.codePostal;
			$scope.sejourEnCours.ville = !$scope.sejourEnCours.ville ?sessionService.getUser().ville : $scope.sejourEnCours.ville;
			$scope.sejourEnCours.telephonePortable = !$scope.sejourEnCours.telephonePortable ? sessionService.getUser().telephonePortable : $scope.sejourEnCours.telephonePortable;
			$scope.sejourEnCours.telephoneFixe = !$scope.sejourEnCours.telephoneFixe ? sessionService.getUser().telephoneFixe : $scope.sejourEnCours.telephoneFixe;

			$scope.sejourAvant = null;
			// on regarde si il y a des séjours avant et si le sejour n'est pas complet
			if($scope.sejourEnCours && !$scope.sejourEnCours.complet && $scope.sejourEnCours.dateEntree && sessionService.getUser().sejours && sessionService.getUser().sejours.length){
				var sejours = $filter('filter')(sessionService.getUser().sejours, {annul: false, complet: true}, true);
				sejours = $filter('orderBy')(sejours, 'dateEntree', true);
				// TODO utiliser filter
				if(sejours && sejours.length){
					for(var i=0; i < sejours.length; i++){
						if(sejours[i].dateEntree && sejours[i].dateEntree < $scope.sejourEnCours.dateEntree){
							$scope.sejourAvant = sejours[i];
							break;
						}
					}
				}

			}

			// --------------- initialisation / ajout /delete pour les pièces jointes ---------------
			// initialisation du tableau des différents pièces jointes à uploader
			$scope.piecesJointesToUpload = PIECES_JOINTES;

			// initialisation du File Upload
			$scope.uploader = new FileUploader({
				url: 'mobile/pieceJointe',
				alias: 'pieceJointe',
				autoUpload: true,
				removeAfterUpload: true,
				headers: { "Authorization" : sessionService.getAccessToken() }
			});

			// appelé avant l'upload pour affiché le bon progressbar
			$scope.uploader.onBeforeUploadItem = function(fileItem){
				$scope.typePieceEnCours = fileItem.formData[0].typePiece;
				$scope.numeroPieceEnCours = fileItem.formData[0].numeroPiece;
			};
			// appelé une fois l'upload de la pièce jointe fini
			$scope.uploader.onCompleteItem = function(fileItem, response, status, headers) {
				// on charge les pièces jointes
				documentService.getPatientPiecesJointes().then(function(response){
					$scope.piecesJointes = response.data;
				});
			};

			$scope.deletePieceJointe = function(typePiece, numeroPiece) {
				documentService.deletePieceJointe(typePiece, numeroPiece).then(function(response){
					// suppression de la liste
					var pieceJointe = $filter('filter')( $scope.piecesJointes, {typePiece: typePiece, numeroPiece: numeroPiece})[0];
					$scope.piecesJointes.splice($scope.piecesJointes.indexOf(pieceJointe), 1);
				});
			}
			// ---------------------------------------------------------------------------------------

			// reception de l'évènement de changement d'une personne à prevenir
			$scope.$on(EVENT.majPersonneAPrevenir, function (event, args) {
				$scope.patient.personneAPrevenirs = args;
				$scope.patientBdd.personneAPrevenirs = args;
			});

			$scope.sejourEnCours.chambreformule = "Standard";
		}

		// ---------------------------------------------
		// --------- envoie pre-admission + validation des champs ----------
		// ---------------------------------------------
		$scope.envoieDossierPreAdmission = function() {
			// validation des champs pour l'envoi du dossier de preadmission
			$scope.preadFields.submitted = true;
			$scope.errorList = [];

			// 1- partie hospitalisation
			if(!$scope.sejourEnCours.dateEntree){
				$scope.errorList.push({"message" : "Date d’entrée obligatoire", "etape": 1});
			}
			if($scope.sejourEnCours.chambreParticuliere && !$scope.sejourEnCours.conditionChambre){
				$scope.errorList.push({"message" : "Conditions de la chambre individuelle", "etape": 1});
			}

			// 2- partie mes informations
			if(!$scope.patient.civilite){
				$scope.errorList.push({"message" : "Civilité obligatoire", "etape": 2});
			}
			if(!$scope.sejourEnCours.nom){
				$scope.errorList.push({"message" :"Nom obligatoire", "etape": 2});
			}
			if($scope.patient.civilite === "Mme" && !$scope.patient.nomJeuneFille){
				$scope.errorList.push({"message" :"Nom de jeune fille obligatoire", "etape": 2});
			}
			if(!$scope.patient.prenom){
				$scope.errorList.push({"message" :"Prénom obligatoire", "etape": 2});
			}
			if(!$scope.patient.mail){
				$scope.errorList.push({"message" :"Mail obligatoire", "etape": 2});
			}
			if(!$scope.patient.dateNaissance){
				$scope.errorList.push({"message" :"Date naissance obligatoire", "etape": 2});
			}
			if(!$scope.sejourEnCours.adresse){
				$scope.errorList.push({"message" :"Adresse obligatoire", "etape": 2});
			}
			if(!$scope.sejourEnCours.codePostal){
				$scope.errorList.push({"message" :"Code postal obligatoire", "etape": 2});
			}
			if(!$scope.sejourEnCours.ville){
				$scope.errorList.push({"message" :"Ville obligatoire", "etape": 2});
			}
			// un téléphone obligatoire (portable ou fixe)
			if(!$scope.sejourEnCours.telephonePortable && !$scope.sejourEnCours.telephoneFixe){
				$scope.errorList.push({"message" :"Téléphone portable ou téléphone domicile obligatoire", "etape": 2});
			}

			// 3- Medecin traitant
			if(!$scope.sejourEnCours.medecinTraitant ){
				$scope.errorList.push({"message" :"Médecin généraliste obligatoire", "etape": 3});
			}

			// 4- Personnes à prévenir
			if(!$scope.patient.personneAPrevenirs || !$scope.patient.personneAPrevenirs.length){
				$scope.errorList.push({"message" :"Personne à prévenir obligatoire", "etape": 4});
			}

			// 5- Prise en charge
			if(!$scope.sejourEnCours.numeroSecu || $scope.sejourEnCours.numeroSecu.length < 15 ){
				$scope.errorList.push({"message" :"N° de sécurité sociale incorrect (15 chiffres)", "etape": 5});
			}
			if(!$scope.sejourEnCours.centreSecu ){
				$scope.errorList.push({"message" :"Organisme d’assurance maladie (CPAM, MSA, RSI...) obligatoire", "etape": 5});
			}
			// si le patient n'est pas l'assuré
			if(!$scope.sejourEnCours.estAssure){
				if(!$scope.sejourEnCours.civiliteAssure){
					$scope.errorList.push({"message" :"Civilité de l’assuré obligatoire", "etape": 5});
				}
				if(!$scope.sejourEnCours.nomAssure){
					$scope.errorList.push({"message" :"Nom de l’assuré obligatoire", "etape": 5});
				}
				if($scope.patient.civiliteAssure === "Mme" && !$scope.sejourEnCours.nomJeuneFilleAssure){
					$scope.errorList.push({"message" :"Nom de jeune fille de l’assuré obligatoire", "etape": 5});
				}
				if(!$scope.sejourEnCours.prenomAssure){
					$scope.errorList.push({"message" :"Prénom de l’assuré obligatoire", "etape": 5});
				}
			}
			// si le patient a une mutuelle
			if($scope.sejourEnCours.mutuelle){
				if(!$scope.sejourEnCours.mutuelleNom){
					$scope.errorList.push({"message" :"Nom de la mutuelle obligatoire", "etape": 5});
				}
				if(!$scope.sejourEnCours.mutuelleAdresse){
					$scope.errorList.push({"message" :"Adresse de la mutuelle obligatoire", "etape": 5});
				}
			}

			// le dossier de pre-admission est envoyé si aucune erreurs de validation
			if (!$scope.errorList.length) {
				// Transformation des numeros au format international
				input = $("#telephonePortableForm");
				if (input.intlTelInput("getSelectedCountryData").iso2 !== "fr") {
					input.val(input.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL));
				}

				// Si chambre individuelle et séjour non complet ==> Affichage de la popup de signature électronique
				if ($scope.sejourEnCours.chambreParticuliere && !$scope.sejourEnCours.complet){
					//FIXME : MeP
					//$('#modalConfirmationChambreIndividuelle').modal('show');
					$scope.validePatientPreadmission();
				} else {
					$scope.validePatientPreadmission();
				}


			}

			// sinon ouverture de la pop-up des erreurs de validation
			else{
				$('#modalErreurPreadmission').modal('show');
			}
		}

		$scope.annulerChambreIndividuelle = function() {
			$('#modalConfirmationChambreIndividuelle').modal('hide');
			console.log("annulerChambreIndividuelle");

			// Chambre individuelle a false et on sauvegarde que c'est une annulation pour mettre un log coté serveur
			$scope.sejourEnCours.chambreParticuliere = false;
			$scope.sejourEnCours.conditionChambre = false;
			$scope.patient.annulationChambreIndividuelle = true;

			$scope.validePatientPreadmission();
		}

		$scope.validerChambreIndividuelle = function() {
			console.log("validerChambreIndividuelle");
			$('#modalConfirmationChambreIndividuelle').on('hidden.bs.modal', function (e) {
				// Ouverture du document à signer
				patientService.getURLChambreIndividuelle($scope.sejourEnCours).then(function(response){
					$scope.urlChambreIndividuelle = $sce.trustAsResourceUrl(response.data[0]);
					$scope.patient.numeroTransactionContralia = response.data[1];
					// Affichage de la popup
					$scope.afficheModalSignatureChambreIndividuelle();
					});
				// Pour ne pas que l'événement se déclenche plusieurs fois
				$(this).off('hidden.bs.modal');
			  }).modal('hide');
		}

		$scope.afficheModalSignatureChambreIndividuelle = function() {
			 $('#modalSignatureChambreIndividuelle').on('hidden.bs.modal', function (e) {
				  $scope.validePatientPreadmission();
				  // Pour ne pas que l'événement se déclenche plusieurs fois
				  $(this).off('hidden.bs.modal');
			  }).modal('show');
		}

		$scope.validePatientPreadmission = function() {
			patientService.validePatientPreadmission($scope.patient).then(function(response){
				// maj de la session
				sessionService.setUser(response.data);
				// le sejour passe à 'complet'
				 $scope.sejourEnCours.complet = true;
				 $('#modalAvisInterne').modal('show');
			});
		}

		/** Change l'étape de preadmission et sauvegarde patient + séjour */
		$scope.changeEtapePreadmissionSave = function(etape){
			$scope.sejourEnCours.etapePreadmission = etape;

			if($scope.patient && $scope.patient.dateNaissance){
				$scope.patient.dateNaissance = new Date($scope.patient.dateNaissance);
				$scope.patient.dateNaissance.setHours(12);
			}
			patientService.updatePatientPreadmission($scope.patient).then(function(response){
				// maj de la session
				sessionService.setUser(response.data);

				// maj de l'objet BDD
				$scope.patientBdd = angular.copy($scope.patient);

				// cas d'une création sejour sans id
				// premier sejour
				if(!$scope.sejourEnCours.sejourId){
					//$scope.sejourEnCours.sejourId = sessionService.getUser().sejours[0].sejourId;
					var sejourId = sessionService.getUser().sejours[0].sejourId;
					sessionService.setProchainSejourId(sejourId);
					$location.path("hospitalisations/preadmission/" + sejourId + "/2");
				}
				else{
					$scope.etapePreadmission = etape;
				}
			});
		}

		/** Appel pour un retour arrière sur l'étape pour la pread */
		$scope.retourArriereEtape = function(etape){
//			if(angular.toJson($scope.patientBdd) !== angular.toJson($scope.patient)){
			if(!$scope.etapesPreadmission[$scope.etapePreadmission-1].complet($scope.patient, $scope.sejourEnCours)){
				$scope.etapeRetour = etape;
				$('#modalRetourPreadmission').modal('show');
			}
			else{
//				$scope.etapePreadmission = etape;
				$scope.changeEtapePreadmissionSave(etape);
			}
		}

		/** Change l'étape de preadmission pour un retour arrière (pas de sauvegarde) */
		$scope.retourEtapePreadmission = function(){
			$scope.etapePreadmission = $scope.etapeRetour;
			// le patientBdd remplace le patient
			$scope.patient = angular.copy($scope.patientBdd);
			$scope.sejourEnCours = $scope.patient.sejourPreAdmission;

			$('#modalRetourPreadmission').modal('hide');
		}

		/** Change l'étape de preadmission lors d'une erreur de validation*/
		$scope.changeEtapePreadmission = function(etape){
			$scope.etapePreadmission = etape;
			$('#modalErreurPreadmission').modal('hide');
		}

		/** Rempli le sejour avec le sejour complet précédent */
		$scope.rempliAvecAncienSejour = function(flagDemandeCopie){

			if (flagDemandeCopie) {
				$scope.sejourEnCours.nom = $scope.sejourAvant.nom;
	 			$scope.sejourEnCours.adresse = $scope.sejourAvant.adresse;
		 		$scope.sejourEnCours.codePostal = $scope.sejourAvant.codePostal;
		 		$scope.sejourEnCours.ville= $scope.sejourAvant.ville;
		 		$scope.sejourEnCours.telephonePortable = $scope.sejourAvant.telephonePortable;
		 		$scope.sejourEnCours.telephoneFixe = $scope.sejourAvant.telephoneFixe;
		 		$scope.sejourEnCours.medecinTraitant = $scope.sejourAvant.medecinTraitant;

		 		//partie assuré
		 		$scope.sejourEnCours.estAssure = $scope.sejourAvant.estAssure;

		 		//il faut réinitialiser la partie assuré
	//	 		initAssure();
		 		$scope.sejourEnCours.civiliteAssure = $scope.sejourAvant.civiliteAssure;
		 		$scope.sejourEnCours.nomAssure= $scope.sejourAvant.nomAssure;
		 		$scope.sejourEnCours.nomJeuneFilleAssure = $scope.sejourAvant.nomJeuneFilleAssure;
		 		$scope.sejourEnCours.prenomAssure = $scope.sejourAvant.prenomAssure;
		 		$scope.sejourEnCours.adresseAssure = $scope.sejourAvant.adresseAssure;
		 		$scope.sejourEnCours.villeAssure = $scope.sejourAvant.villeAssure;
		 		$scope.sejourEnCours.codePostalAssure = $scope.sejourAvant.codePostalAssure;
		 		$scope.sejourEnCours.telephonePortableAssure = $scope.sejourAvant.telephonePortableAssure;
		 		$scope.sejourEnCours.telephoneFixeAssure = $scope.sejourAvant.telephoneFixeAssure;
		 		$scope.sejourEnCours.mailAssure = $scope.sejourAvant.mailAssure;

		 		//partie médicales de la préad
		 		$scope.sejourEnCours.numeroSecu= $scope.sejourAvant.numeroSecu;
		 		$scope.sejourEnCours.centreSecu= $scope.sejourAvant.centreSecu;
		 		$scope.sejourEnCours.secuCentPercen = $scope.sejourAvant.secuCentPercen ;
		 		$scope.sejourEnCours.mutuelle = $scope.sejourAvant.mutuelle;
		 		$scope.sejourEnCours.mutuelleNom = $scope.sejourAvant.mutuelleNom;
		 		$scope.sejourEnCours.mutuelleAdresse = $scope.sejourAvant.mutuelleAdresse;
			}

	 		// Mise à jour du flag en BDD
			patientService.updateFlagCopiePreadmission($scope.patient).then(function(response){
				// maj de la session
				sessionService.setUser(response.data);

				$('#modalCopierInfoPreadmission').modal('hide');

			});


		}

		// ---------------------------------------

		// -------------------- AUTRES ------------------------
		$scope.createMessageClinique = function() {
			$http({
				method: "POST",
				url: "mobile/echange/messageClinique",
				data: $.param({"message": $scope.message, "destination": "CLINIQUE", "source": "PATIENT"}),
				headers: {"Content-Type": "application/x-www-form-urlencoded"}
			});
			$scope.message = undefined;
			$('#modalSuccesQuestion').modal('show');
		}

		/**
		 * Retourne le libelle de l'hospitalisation
		 * @param sejour : sejour à calculer pour le libelle
		 * @param donneesMedicalesSejour
		 */
		function getLibelleHospitalisation(sejour, donneesMedicalesSejour){
			var libelle = sejour.dateEntree ? $filter('date')(sejour.dateEntree, "dd MMMM yyyy") + ":" : ":";

			if(donneesMedicalesSejour && donneesMedicalesSejour.interventions){
				var interventions = donneesMedicalesSejour.interventions;
				for(var i=0; i < interventions.length; i++){
					var intervention = interventions[i];
					if(intervention && intervention.libelle && intervention.libelle.trim()){
						libelle += ' ' + intervention.libelle;
					}
				}
			}
			return libelle.trim();
		}

		$scope.setAvisPage = function(page) {
			$scope.avis.page = page;
		}

		$scope.sendSmsAppli = function(name) {
			$http.post("mobile/sms/appli/" + name);
		}

		// ----------------------------------------------------

		$scope.init = function() {

			if ($routeParams.appli === "mobile") {
				$scope.setMobileApp();
			}

			// Gestion du retour de la signature électronique pour l'envoi de la chambre individuelle
			if ($routeParams.mode === "SIGN") {
				$scope.afficheCloseButton=true;
				if ($routeParams.userAction === "OK") {
					$scope.messageRetourChambreIndividuelle = "Votre demande de chambre individuelle a bien été signé électroniquement. Vous pouvez désormais fermer cette popup."
				} else {
					$scope.messageRetourChambreIndividuelle = "La signature de la chambre individuelle a été annulée."
				}
			}

			$scope.sejourEnCours = {};
			$scope.Commun.etatHospitalisation = "inconnu";
			// récupération du séjour
			var sejourId = parseInt($routeParams.sejourId);
			if( sejourId ){
				CommunService.sejourId = sejourId;
				$scope.sejourEnCours = $filter('filter')( sessionService.getUser().sejours, { sejourId: sejourId}, true)[0];

				// si il y a un séjour alors : récupération des données médicales associées
				if($scope.sejourEnCours){
					patientService.getDonneMedicalesSejour($scope.sejourEnCours.sejourId).then(function(response){
						$scope.donneesMedicalesSejour = response.data;
						// Calcul du libelle de l'hospitalisation : Date + libelle des interventions
						$scope.libelleHospitalisation = getLibelleHospitalisation($scope.sejourEnCours, $scope.donneesMedicalesSejour);
						// si données médicales, on actualise le statut
						if($scope.donneesMedicalesSejour){
							// nécéssité de mettre à jour Commun
							if($scope.donneesMedicalesSejour.present){
								$scope.Commun.etatHospitalisation = "en cours";
							}
							else if($scope.donneesMedicalesSejour.prevue){
								$scope.Commun.etatHospitalisation = "prevue";
							}
							else if($scope.donneesMedicalesSejour.sorti){
								$scope.Commun.etatHospitalisation = "terminee";
							}
						}
					});
				}
			}

			// ------------------------------------------------
			$scope.avis = {};
			$scope.initPreAdmission();

			// init liens patient avec personne à prevenir
			$scope.chargerPersonneAPrevenir();
			// on charge les pièces jointes
			documentService.getPatientPiecesJointes().then(function(response){
				$scope.piecesJointes = response.data;
			});

			$scope.liensPatient = LIENS_PATIENT;
			$scope.etapesPreadmission = ETAPES_PREADMISSION;

			var dateNaissance;
			// Page remboursement santé
			if (sessionService.getUser().dateNaissance){
				const d = new Date(sessionService.getUser().dateNaissance);
				dateNaissance = d.getFullYear()+'-'+("0" + (d.getMonth() + 1)).slice(-2)+ '-'+ ("0" + d.getDate()).slice(-2);
			}
			$scope.currentProjectUrl = $sce.trustAsResourceUrl('https://www.mrs.beta.gouv.fr/institution/310797485/mrsrequest/iframe/?first_name='+sessionService.getUser().prenom+'&last_name='+sessionService.getUser().nom+'&birth_date='+dateNaissance+'&email='+sessionService.getUser().mail+'&origin='+window.location.href);

			$scope.getRemboursementsTransport();

			// notice
			if(!$routeParams.etape){
				$('#modalAccueilPreadmission').on('hidden.bs.modal', function (e) {
					// Si il y a déja eu un séjour avant
					if ($scope.sejourAvant && !$scope.sejourEnCours.copieSejourAvant) {
						$('#modalCopierInfoPreadmission').modal('show');
					}
				  }).modal('show');
			}

			// Si retour de paiement pour la chambre individuelle on recharge les infos du séjour pour le statut du paiement
			if ($routeParams.montant && $routeParams.reference && $routeParams.codeErreur ) {
				patientService.updatePatientPreadmission($scope.patient).then(function(response){
					// maj de la session
					sessionService.setUser(response.data);

					// maj de l'objet BDD
					$scope.patientBdd = angular.copy($scope.patient);

					$scope.sejourEnCours = $filter('filter')( sessionService.getUser().sejours, { sejourId: sejourId}, true)[0];

				});
			}

		}

		$scope.getRemboursementsTransport = function() {

			$http({method: 'GET', url: 'mobile/remboursements-transport'}).then(function(resp) {
				$scope.listRbt = resp.data;
			});
		}

		$scope.getStatut = function(rbt) {
			if (rbt) {
				rbt.statut = "Soumise";
				$http({method: 'GET',
					url: 'https://www.mrs.beta.gouv.fr/institution/310797485/mrsrequest/' + rbt.numero + '/status/?origin='+window.location.href,
					headers: {'Authorization': undefined, 'Cache-Control': undefined,'Pragma':undefined}
				}).then(function(resp) {
					if ("0" == resp.data.status) {
						rbt.statut = "Soumise";
					} else if ("999" == resp.data.status) {
						rbt.statut = "Rejetée";
					} else if ("1000" == resp.data.status) {
						rbt.statut = "En cours";
					} else if ("2000" == resp.data.status) {
						rbt.statut = "Validée";
					}
				});
			}
		}

		$window.uploadFileMRS = function() {
			/*
			console.log('uploadFileMRS');
			document.getElementById('mrsrequest').contentWindow.postMessage(
				      '{"file_name": "DEMANDE.pdf", "pmt_url": "https://espacepatient.clinique-pasteur.com/mobile/documents/document-admin/Bearer%20eyJhbGciOiJIUzUxMiJ9.eyJwcmVub20iOiJURVNUIiwiaWQiOjExMzQ1LCJyb2xlIjoiUEFUSUVOVCIsIm5vbSI6IlRFU1QiLCJtb2JpbGUiOmZhbHNlfQ.VZj27WYUYnN56OsE9pTG0AnCu8EQCp4CEztv8nl2_yDtqFnw5yCgRbWy3fBd5pVYqJTI1FS_QKUmiJn1TTLIkw/?liPast=7395939&type=2"}',
				      'https://www.mrs.beta.gouv.fr'
				    );
				    */
		}

		// Récupération de l'uuid pour la demande de remboursement santé
		 $($window).on("message", function(e){
			 console.log('soumission formulaire validée');
             $http({method: 'POST', url: 'mobile/remboursement-transport', data: e.originalEvent.data.mrsrequest_uuid}).then(function(resp) {
            	 $scope.getRemboursementsTransport();
 			});
          // Pour ne pas que l'événement se déclenche plusieurs fois
             $(this).off('message');
          });

		$scope.init();
	}

	hospitController.$inject = ['$scope', '$http', '$window',
								'sessionService', 'CommunService', 'HospitService',
								'PersonneAPrevenirService', 'patientService', 'documentService',
								'$routeParams', '$filter', 'LIENS_PATIENT', 'PIECES_JOINTES', 'ETAPES_PREADMISSION',
								'FileUploader', 'EVENT', '$location', '$sce' ];

	angular
	  .module('clientApp')
	  .controller('HospitController', hospitController);

})(angular);