angular.module('clientApp')
  .directive('stickyNavbar', function($window) {
    return {
    	
      restrict: 'A',
      
      link: function(scope, element, attrs) {
    	  
        var orignOffsetTop = element[0].offsetTop;
        
        scope.condition = function() {
          return $window.pageYOffset > orignOffsetTop;
        };
        
        // Cette directive permet la configuration du mode sticky pour la navBar (boostrap3)
        // mise à jour des class Css et des styles quand on scroll.
        angular.element($window).bind('scroll', function() {
        	
          scope.$apply(function() {
        	  
        	  // La partie Fiche User
        	  var userDivZone =$("#zone-header-patient-sticky");    	
        	  
        	 // --------------------------------- Mode STICKY------------------------------
            if (scope.condition()) {
            	
            	// Ajoute navbar-fixed-top à la navBar pour permettre le Sticky
            	angular.element(element).addClass('navbar-fixed-top');
            	// alignement pour la partie user
            	angular.element(userDivZone).css("text-align", "left");
            	
            	// Permet de faire apparaitre (supprime Hidden) le logo et le div user (à droite) en mode Sticky
            	 $('#logoNavButton').removeClass('hidden');
            	 $('#connexion-user-navbar-sticky').removeClass('hidden');
            	 $('#zone-patient-titre-sticky').removeClass('hidden');
            	
            	// Permet de positionner le logo et la fleche user en mode sticky pour être en face de celui du mode sans sticky
            	$('#userLink').addClass('pull-right');
            	$('#userLink').css("margin-right", "-5px");
            	
            	// Permet de positionner le logo pasteur en mode sticky pour être en face du CP du logo du mode sans sticky
            	$('#logoLink').addClass('pull-left');
            	$('#logoLink').css("padding-left", "32px");
            	
            	// Landscape phone to portrait tablet 
            	if (window.matchMedia("(max-width: 767px)").matches) {
            		
            		$('#logoNavButton').addClass('hidden');
            		$('#connexion-user-navbar-sticky').addClass('hidden');
            		$('#stickyNavbarContainer').removeClass("container");
            		$('#stickyNavbarContainer').css("margin-left", "15px");
            		
            		
            		$('#logoNavButtonheader').removeClass('hidden');
            		$('#logoNavButtonheader').addClass('text-center');
            		$('#logoNavButtonheader').css("padding-left", "52px");
            		
            		$('#small-headerBase-content_resp').removeClass('hidden');
            		$('#small-headerBase-content_resp').css("padding-right", "26px");
            		$('#small-headerBase-content_resp').css("padding-top", "5px");
            		
            		$('#logoLink').removeClass('pull-left');
            		$('#logoLink').css("padding-left", "0px");
            		$(".navbar-default").css("height", "unset");
            		$(".navbar-collapse.in").css("overflow-y","unset");
            		$(".open>ul.dropdown-menu").css("background","#0090d7");
            		$('#menu-connect_resp .menu-main, a.menu-main').css("display", "inline-block");
            		
            		
            	} else {
            		$('#logoNavButtonheader').addClass('hidden');
            		$('#small-headerBase-content_resp').addClass('hidden');
            		
            		$(".navbar-default").css("height", "40px");
            		$(".open>ul.dropdown-menu").css("background","#0090d7");
            		$(".navbar-default").css("padding-left", "0px");
            		$('#menu-connect_resp .menu-main, a.menu-main').css("display", "block");
            	}
            	
            // --------------------------------- Mode Sans STICKY------------------------------	
            } else {
            	
	            angular.element(element).removeClass('navbar-fixed-top');
	            
	            $('#menu-connect_resp .menu-main, a.menu-main').css("height", "40px");
	            $(".navbar-default").css("padding-left", "0px");
	            $(".ul.navbar-nav ").css("margin-left", "21px");
	            $("#sousMenusSticky").css("top","133px");
	            
	            $('#connexion-user-navbar-sticky').addClass('hidden');
	            $('#zone-patient-titre-sticky').addClass('hidden');
	            $('#logoNavButtonheader').addClass('hidden');
	            $('#small-headerBase-content_resp').addClass('hidden');
	            $('#logoNavButton').addClass('hidden');
	            
	            $('#stickyNavbarContainer').removeClass("container");
	            $('#logoLink').removeClass('pull-left');
	            $('#userLink').removeClass('pull-right');
	            
	            
	            // ajout de z-index en mode mobile dans le cas "sans sticky"
	            if (window.matchMedia("(max-width: 767px)").matches) {
	            	
	            	$("#menu-connect_resp").css("padding-left", "0px");
	            	$("#menu-connect_resp").css("padding-right", "0px");
	            	$("#zone-header-patient").css("z-index", "999");
	            	$(".navbar-collapse.in").css("overflow-y","unset");
	            	$(".open>ul.dropdown-menu").css("background","#0090d7");
	            }
            }
          });
        });
        
      }
    };
  });