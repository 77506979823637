(function(angular){
	
	/**
	 * Service de login de l'espace patient
	 * @param $http
	 * @param sessionService
	 * @param $location
	 * @returns
	 */
	function loginService($http, sessionService, echangeService, AccueilService, CommunService){
		
		this.isLoggedIn = function(){
			return sessionService.getUser() != null;
		};
		
		this.logIn = function(mail, password){
			return 	$http({
				method: "POST",
				url: "mobile/authentification",
				data: $.param({
					"mail": mail,
					"password": password
				}),
				headers: {"Content-Type": "application/x-www-form-urlencoded"}}).then(function(response) {
					var patient = response.data;
					// si inscription validée et utilisateur non gemini : maj token
					if (patient.patientId && (!patient.geminiNonInscrit && patient.inscriptionValidee)) {
						//maj du token
						$http.defaults.headers.common['Authorization'] = 'Bearer ' + patient.token;
						sessionService.setAccessToken($http.defaults.headers.common['Authorization']);
						
						//maj du patient
						sessionService.setUser(patient);
						
						//maj de sejour courant
						var sejourId = patient.prochainSejour ? patient.prochainSejour.sejourId : 0;
						sessionService.setProchainSejourId(sejourId);
						
						// ---------- TODO : création DTO pour ces éléments, utilisés dans le menu ---------
						// charge les messages
						echangeService.actionChargerEchange();
						
						// ---------------------------------------------------------------------------------
					}
					return patient;
				});
		};
		
		this.motDePasseOublieMail = function(mail){
			return $http.post('mobile/motDePasseOublieMail ', mail);
		}
		
		this.motDePasseOublieID = function(id){
			return $http.post('mobile/motDePasseOublieID ', id);
		}
		
		this.inscription = function(patient){
			return $http.post('mobile/inscription ', patient);
		}
		
		this.validationInscription = function(patient){
			return $http.post('mobile/validationInscription ', patient);
		}
		
		this.logOut = function(){
			// A faire
		}
		
		this.renvoyerCodesProvisoires = function(patient){
			return $http.post('mobile/renvoyerCodesProvisoires ', patient);
		}
		
	}

	loginService.$inject = ['$http', 'sessionService', 'echangeService', 'AccueilService', 'CommunService'];

	angular
	    .module('clientApp')
	    .service('loginService', loginService);
	
})(angular);
