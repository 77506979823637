angular.module("clientApp").factory("NotifPatientService", ['$http', 'CommunService',  function($http, CommunService) {

	var getNotifsPatient = function() {
		return $http.get("mobile/get-notifications-patient").then(function (response) {
			console.log("NotifPatientService : get notifications Patient");
			$scope.lastNotifsPatient = response.data;
		});
	}
	
	var readNotifsPatient = function(notif) {
		if (notif.status == "NEW"){
			var promise = $http.put('mobile/read-notifications-patient', notif)
			.then(function(response) {
				console.log("NotifPatientService - readNotifsPatient : "+ response.data);
				CommunService.getNotifsPatient();
			});
		} else {
			console.log("Notif deja lue ");
		}
	}

}]);
