/**
 * Medecin service
 */
angular.module("clientApp").factory("MedecinGeneralisteService", ['$http', 'CommunService',  function($http, CommunService) {

	var getMedecinGeneraliste = function() {
		return $http.get("mobile/medecin").then(function(response) {
			var medecin = "";
			var departementMedecin = null;
			if (response.data && response.data.result != "") {
				medecin = angular.fromJson(response.data.result);
			}
			if (medecin && medecin.codePostal && medecin.codePostal.length > 1) {
				departementMedecin = medecin.codePostal.substring(0,2);
				CommunService.departementMedecin = departementMedecin;
			}
			return {
				medecinGeneraliste : medecin,
				autorisation : angular.fromJson(response.data.autorisation),
				departementMedecin : departementMedecin
			}
		});
	}
	
	var medecinGeneralisteSubmit = function(form, scope){
		form.submitted = false;
		if(form.$valid) {
			var params = new Array();
			params["medecinGeneraliste"] = angular.toJson(scope.typeaheadObject.medecinGeneraliste);
			params["autorisation"] = scope.typeaheadObject.autorisation;
			
			$("#loading").addClass("modal-pasteur"); 
			$http.get("creerMedecinGeneraliste.action", { params : params}).then(function(response) {
				CommunService.departementMedecin = scope.typeaheadObject.departementMedecin;
				scope.medecinGeneraliste = scope.typeaheadObject.medecinGeneraliste;
				scope.autorisation = scope.typeaheadObject.autorisation;
				CommunService.fermerPopupMedecinGeneraliste();
			});
		} else {
			form.submitted = true;
		}
	};
	
	var medecinGeneralisteSupprimer = function(form, scope) {
		form.submitted = false;
		if (form.$valid){
			$("#loading").addClass("modal-pasteur"); 
			$http.get("supprimerMedecinGeneraliste.action").then(function(response) {
				scope.medecinGeneraliste = undefined;
				scope.autorisation = undefined;
				scope.typeaheadObject.departementMedecin = undefined;
				CommunService.departementMedecin = undefined;
				scope.typeaheadObject.medecinGeneraliste = undefined;
				scope.typeaheadObject.autorisation = undefined;
				scope.medecinGeneralisteForm.$setPristine();
				CommunService.fermerPopupMedecinGeneraliste();
			});
		} else {
			form.submitted = true;
		}
	};

	var getMedecins = function(departement, partieNom) {
		return $http.get("getMedecins.action", {params: {departement: departement,partieNom: partieNom}}).then(function(response){
	    	  return response.data.medecins;
	      });
	};
	
	return{		
		init : function(scope) {
			return getMedecinGeneraliste().then(function(data) {
				return {
					medecinGeneraliste : data.medecinGeneraliste,
					autorisation : data.autorisation,
					departementMedecin : data.departementMedecin,
					getMedecins : function(val){
						return getMedecins(scope.typeaheadObject.departementMedecin, val);
					},
					medecinGeneralisteSubmit : function(form){
						medecinGeneralisteSubmit(form, scope);
					},
					medecinGeneralisteSupprimer : function(form) {
						medecinGeneralisteSupprimer(form, scope);
					}
				};
		 	});
		}
	}
}]);