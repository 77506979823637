angular.module("clientApp").factory("HospitService", ['$http', 'CommunService', function($http, CommunService) {
	enrichissementParam = function(params) {
		if (!params) {
			params = {};
		}
		params.navigateur = {
			CookieEnabled : navigator.cookieEnabled,
			Language : navigator.language,
			Resolution : window.screen.width + " x " + window.screen.height,
			UserAgent : navigator.userAgent
		}
		return params;
	};

	return {
		creerAvis : function(avis) {
			avis.userAgent = navigator.userAgent;
			return $http.post("mobile/creation-avis-site", JSON.stringify(avis));
		}
	}
	
	
	
	
	
	
	
}]);