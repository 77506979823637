(function(angular){
	function questionnaireService($http, $q ){

		this.getQuestionnaireById = function(id){
			var deferred = $q.defer();
			$http.get("mobile/quiz/questionnaire/"+id)
				.then(function(data) {
					  deferred.resolve(data);
					  console.log('getQuestionnaireById sucess');
		       }, function (msg, code) {
		          deferred.reject(msg);
		          console.log('getQuestionnaireById echec');
		       });
			return deferred.promise;
		}
		
		
		this.initNewQuestionnaireUtilisateur = function (borneId, questionnaireId){
			var quizzBorne = {};
			quizzBorne.id = borneId;
			var questionnaire= {};
			questionnaire.id = questionnaireId;
			var questionnaireUtilisateur = {}
			questionnaireUtilisateur.quizBorne = quizzBorne;
			questionnaireUtilisateur.questionnaireBorne = questionnaire;
			
			var deferred = $q.defer();
				$http.post("mobile/quiz/questionnaire_utilisateur", questionnaireUtilisateur )
				.then(function(data) {
					  deferred.resolve(data);
					  console.log('questionnaire_utilisateur sucess');
		       }, function (msg, code) {
		          deferred.reject(msg);
		          console.log('questionnaire_utilisateur echec');
		       });
			return deferred.promise;
		}
		
		// une borne 'virtuelle' représente l'application espace patient, Uuid ESPACE_PATIENT
		// le questionnaire est associé à la borne
		this.getBorneQuizzEspacePatient = function() {
			var deferred = $q.defer();
			$http.get('mobile/quiz/getBorneByUuid/ESPACE_PATIENT')
				.then(function(data) {
					  deferred.resolve(data);
					  console.log('getBorneQuizzEspacePatient sucess');
		       }, function (msg, code) {
		          deferred.reject(msg);
		          console.log('getBorneQuizzEspacePatient echec');
		       });
			return deferred.promise;
			
		}
		
		
		this.getQuestionnaireByBorneUUID = function(uuid){
			var deferred = $q.defer();
			$http.get("mobile/quiz/getBorneByUuid/"+uuid)
				.then(function(data) {
					  deferred.resolve(data.questionnaireBorne);
					  console.log('questionnaire_borne sucess');
		       }, function (msg, code) {
		          deferred.reject(msg);
		          console.log('questionnaire_borne echec');
		       });
			return deferred.promise;
		}
		
		
	
		this.createReponseUtilisateur = function (listeReponses){
			var deferred = $q.defer();
			$http.post("mobile/quiz/createReponseUtilisateur", listeReponses)
				.then(function(data) {
					  deferred.resolve(data);
		       }, function (msg, code) {
		          deferred.reject(msg);
		       });
			return deferred.promise;
			
		}
		
		
		
		
		
		
	}

	questionnaireService.$inject = ['$http', '$q'];
	angular
	    .module('clientApp')
	    .service('questionnaireService', questionnaireService);
	
})(angular);
