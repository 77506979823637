(function(angular){

	/**
	 * Controller des echanges
	 */
	function echangeController($http, $scope, CommunService, $routeParams, sessionService, echangeService) {
		$scope.Commun = CommunService;
			
		// chargement des échanges
		echangeService.actionChargerEchange();
		
		// récupération du paramètre de route si existe
		if($routeParams.contactMedicalId){
			CommunService.contactMedicalId =  parseInt($routeParams.contactMedicalId);
		}
		else{
			CommunService.contactMedicalId = null;
		}
	
		$("#fichierClinique").fileupload({
			method: "POST",
			add: function (e, data) {
				$("#envoyerClinique").off("click").on("click", function () {
					if ($scope.file && $scope.message) {
						data.formData = { "message": $scope.message, "source": "PATIENT", "destination": "CLINIQUE", "nomFichier": $scope.file.name };
						if (CommunService.contactMedicalId) {
							data.formData.contactMedicalId = CommunService.contactMedicalId;
						}
						data.submit().success(function (result, textStatus, jqXHR){
							echangeService.actionChargerEchange();
						});
						$scope.message = undefined;
						$scope.file = undefined;
					} else {
						$scope.envoyerClinique();
					}
				});
			},
			url: "mobile/echange/messageCliniquePJ",
			headers: {'Authorization': sessionService.getAccessToken()}
		});
	
		$("#fichierProches").fileupload({
			dataType: "text",
			add: function (e, data) {
				$("#envoyerProches").off("click").on("click", function () {
					if ($scope.file && $scope.message) {
						data.formData = { "message": $scope.message, "source": "PATIENT", "destination": "FAMILLE" };
						data.submit().success(function (result, textStatus, jqXHR){
							echangeService.actionChargerEchange();
						});
						$scope.message = undefined;
						$scope.file = undefined;
					} else {
						$scope.envoyerProches();
					}
				});
			},
			url: "mobile/echange/messageProche",
			headers: {'Authorization': sessionService.getAccessToken()}
		});
	
		// TODO : à revoir
		$scope.scrollerEchanges = function(source) {
			if (!CommunService.scrollDone) {
				CommunService.scrollTo("echanges-nouveau");
				CommunService.scrollDone = true;
			}
			return false;
		};
	
		$scope.setEchangesLus = function(source) {
			var echange = {};
			echange.source = source;
			echange.contactMedicalId = CommunService.contactMedicalId;
			$http.post('mobile/echange/setEchangesLus', echange);
		};
	
		$scope.getContactMedical = function() {
			if ($scope.contactMedical && $scope.contactMedical.id === CommunService.contactMedicalId) {
				return $scope.contactMedical;
			}
			if (CommunService.contactMedicals) {
				for (var i = 0; i < CommunService.contactMedicals.length; i++) {
					var contactMedical = CommunService.contactMedicals[i];
					if (CommunService.contactMedicalId === contactMedical.id) {
						$scope.contactMedical = contactMedical;
					}
				}
			}
			return $scope.contactMedical;
		};
	
		$scope.envoyerProches = function() {
			if ($scope.message) {
				var echange = {};
				echange.message = $scope.message;
				echange.source = "PATIENT";
				echange.destination = "FAMILLE";
				
				$http.post('mobile/echange/messageProche', echange).then(function(resp) {
					echangeService.actionChargerEchange();
				});
				
				$scope.message = undefined;
			}
		};
	
		$scope.envoyerClinique = function() {
			if ($scope.message) {
				var echange = {};
				echange.message = $scope.message;
				echange.source = "PATIENT";
				echange.destination = "CLINIQUE";
				echange.contactMedicalId = CommunService.contactMedicalId;
				
				$http.post('mobile/echange/messageClinique', echange).then(function(resp) {
					echangeService.actionChargerEchange();
				});
				$scope.message = undefined;
			}
		};
	
		$scope.setFile = function(element) {
			$scope.$apply($scope.file = element.files[0]);
		};
		
		$scope.afficherPopupEnSavoirPlus = function() {
			$('#modalEnSavoirPlus').modal('show');
		};
	}
	
	echangeController.$inject = ['$http', '$scope', 'CommunService', '$routeParams', 'sessionService', 'echangeService'];

	angular
	  .module('clientApp')
	  .controller('EchangeController', echangeController);

})(angular);