(function(angular){

	// TODO :  à mettre dans circuitService
	function programmeSanteService($http){
			
		/** Création champs de saisie de valeur  */
		this.createChampSaisieValeur = function(champSaisieValeurs){
			return $http.post('mobile/programmesante/champsaisie-valeurs',champSaisieValeurs);
		};
		
		this.getProgrammeDocuments = function() {
			return $http.get("mobile/programmesante/programme-documents");
		};
		
	}

	programmeSanteService.$inject = ['$http'];

	angular
	    .module('clientApp')
	    .service('programmeSanteService', programmeSanteService);
	
})(angular);

