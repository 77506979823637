(function(){
	 'use strict';
	 
	 /* Fonction indiquant si l'etape 5 de la pre-admission est validée
	  * Le clic sur les etapes 6,7,8 sera possible seulement si l'etape 5 est validée
	  */
	 var etape5 = function(patient, sejour){ return ( (sejour.numeroSecu && sejour.numeroSecu.length >= 15) && sejour.centreSecu ) && ( sejour.estAssure || (sejour.nomAssure && sejour.prenomAssure && (sejour.civiliteAssure == 'Mr' || sejour.nomJeuneFilleAssure))) && (!sejour.mutuelle || (sejour.mutuelleNom && sejour.mutuelleAdresse  )) };
	 
	 // ensemble des constantes utilisées pour l'espace patient
	 angular.module('clientApp')
	 	 // actions spécifiques des circuits
	 	 .constant('CIRCUIT_ACTIONS', [
	 		'PREADMISSION',
	 		'PROGRAMME_SANTE',
	 		'MESSAGERIE',
	 		'UPLOAD',
	 		'CEQUEJEDOISSAVOIR',
	 		'PERSONNEAPREVENIR',
	 		'CONSENTEMENT',
	 		'DOCUMENT_ADMINISTRATIF',
	 		'DOSSIER_MEDICAL',
	 		'AVIS',
			'CONSTANTES',
			'QUESTIONNAIRE'
	 	 ])
	 	 // liens des patients
	 	 .constant('LIENS_PATIENT', [
				"Père",
				"Mère",
				"Enfant",
				"Conjoint(e)",
				"Concubin(e)",
				"Autre"
	 	 ])
	 	 // ensemble des types de pièces jointes d'un patient
	 	 .constant('PIECES_JOINTES', [
	 		 	{"typePiece" : "piece_identite", "numeroPiece" : 1, "display": "Pièce d’identité", "image" :"pct_scan_carte_identite_recto.png"},
	 		 	{"typePiece" : "piece_identite", "numeroPiece" : 2, "display": "Pièce d’identité", "image" :"pct_scan_carte_identite_verso.png"},
	 		 	
	 		 	{"typePiece" : "carte_vitale", "numeroPiece" : 1, "display": "Carte vitale", "image" :"pct_scan_carte_vitale_recto.png"},
	 		 	
	 		 	{"typePiece" : "mutuelle", "numeroPiece" : 1, "display": "Attestation de mutuelle (en cours de validité)", "image" :"pct_scan_carte_mutuelle_recto.png"},
	 		 	{"typePiece" : "mutuelle", "numeroPiece" : 2, "display": "Attestation de mutuelle (en cours de validité)", "image" :"pct_scan_carte_mutuelle_verso.png"},
	 	 ])
	 	 // ensemble des étapes de la pre-admission
	 	  .constant('ETAPES_PREADMISSION', [
	 		 {"numero" : 1, "nom" : "Hospitalisation", "complet": function(patient, sejour){ return (sejour.dateEntree && (!sejour.chambreParticuliere || sejour.conditionChambre))}, "obligatoire" : true},
	 		 {"numero" : 2, "nom" : "Mes informations", "complet": function(patient, sejour){ return (patient.civilite && sejour.nom && patient.prenom && patient.dateNaissance && sejour.adresse && sejour.codePostal && sejour.ville && patient.mail) && (sejour.telephonePortable || sejour.telephoneFixe) && ( patient.civilite == 'Mr' || patient.nomJeuneFille)}, "obligatoire" : true},
	 		 {"numero" : 3, "nom" : "Médecin traitant", "complet": function(patient, sejour){ return sejour.medecinTraitant}, "obligatoire" : true},
	 		 {"numero" : 4, "nom" : "Personnes à prévenir", "complet": function(patient, sejour){ return (patient.personneAPrevenirs != null && patient.personneAPrevenirs.length)}, "obligatoire" : true},
	 		 {"numero" : 5, "nom" : "Prise en charge", "complet": etape5 , "obligatoire" : true},
	 		 {"numero" : 6, "nom" : "Documents", "complet": etape5, "obligatoire" : false},
	 		 {"numero" : 7, "nom" : "Compléments", "complet": etape5, "obligatoire" : false},
	 		 {"numero" : 8, "nom" : "Envoi du dossier", "complet": etape5, "obligatoire" : false}
	 	])
	 	// options pour le composant telephone : intlTelInput
	 	.constant('OPTIONS_TELEPHONE', {
	 		autoPlaceholder: false,
			defaultCountry: "fr",
			preferredCountries: ["fr"],
			utilsScript: "assets/node_modules/intl-tel-input/lib/libphonenumber/build/utils.js"
	 	})
	 	// évenement à broadcaster et à recevoir
	 	.constant('EVENT', {
	 		majPersonneAPrevenir: 'maj-personne-prevenir'
		 })
		// codes pour les conditions visibles des champs de saisies d'un questionnaire
		.constant('CODE_VISIBLE_SI', [
				"CIVILITE_PATIENT"
	 	 ])
})();