(function(angular){
	angular.module('clientApp').component('customisationKey', {
		templateUrl: "./js/component/customisationKey/customisationKey.html",
		controller: CustomisationKeyController,
		bindings: {
			key: '@',
			lang: '@',
			type: '@',
			bal:'@',
			id: '@?',
		}
	});
	CustomisationKeyController.$inject = ['parametersService', '$http', '$scope'];

	/**
	 * Directive pour récupérer les clés de parametrage en base et les afficher en html dans un div
	 */
	function CustomisationKeyController(parametersService, $http, $scope) {
		var ctrl = this;
		
		this.$onInit = function () {
			parametersService.getParamByKeyLang(this.key, this.lang).then(function(data){
				ctrl.value = (data.value === undefined ? this.key : data.value);
				ctrl.value = ctrl.value.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',"");	
			});
		};
		
	}
})(angular);