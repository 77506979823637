(function(angular){
	angular.module('clientApp').component('questionnaire', {
		templateUrl: "./js/component/questionnnaire/questionnaireComponent.html",
		controller: QuestionnaireController,
		bindings: {	}
	});

	QuestionnaireController.$inject = ['questionnaireService', '$http', '$scope', '$timeout'];

	function QuestionnaireController(questionnaireService, $http, $scope, $timeout) {
		var ctrl = this;
	
		ctrl.display = "display";
		ctrl.reponsesUtilisateur = [];
		ctrl.submited = false;

		// initialiser les données du formulaire : identifiants de borne et questionnaires, liste de Q&R
		this.$onInit = function () {
			questionnaireService.getBorneQuizzEspacePatient().then(function(res){
				//console.log("getBorneQuiz sucess");
				ctrl.borne = res.data;
				ctrl.texteAccueil = res.data.questionnaireBorne.texteAccueil;
				ctrl.questions = res.data.questionnaireBorne.questions; 
				ctrl.initReponsesUtilisateur(ctrl.questions);	
			});
		};
		
		
		// init du model lié aux radio button, -1 par defaut.
		this.initReponsesUtilisateur = function (questions){
			angular.forEach(questions, function(q) {
				q.rep={"valeurChoix": -1};
			});
		}
		

		this.submitQuestionnaire = function(){
			ctrl.submited = true
		
			// creation d'un nouveau questionnaire utilisateur pour y associer les réponses utilisateur.
			// console.log("init questionnaire utilisateur done : " + ctrl.borne.id + " , " + ctrl.borne.questionnaireBorne.id );
			questionnaireService.initNewQuestionnaireUtilisateur(ctrl.borne.id, ctrl.borne.questionnaireBorne.id).then(function(res){
				ctrl.questUtilisateur = res.data;
				
				// reformatter les reponses pour contruire la liste de reponseUtilisateur a envoyer.
				ctrl.reponses = [];
				angular.forEach(ctrl.questions, function(q) {
					if (q.rep.valeurChoix !== -1){ 
						//-1 est la valeur d'init par defaut : la question n'a pas eu de réponse, elle ne sera pas comptabilisée
						var rp = {};
						rp.valeur = q.rep.valeurChoix;
						rp.borneLocalisation = ctrl.borne.localisation;
						rp.question = {"id": q.id};
						rp.quizBorne = {"id": ctrl.borne.id };
						rp.questionnaireUtilisateur = ctrl.questUtilisateur;
						ctrl.reponses.push(rp);
					}
				});
				
				// envoyer la liste de réponses.
				//console.log("reponses a envoyer : " + JSON.stringify(ctrl.reponses));
				questionnaireService.createReponseUtilisateur(ctrl.reponses).then(function(res){
					console.log("# reponses envoyées : " + ctrl.reponses.length  ); 
				});
				
				//clear and reset 
				ctrl.initReponsesUtilisateur(ctrl.questions);	
			});
			
		};
		
	}
})(angular);