(function(angular){

	/**
	 * Controller utilisé pour l'utilisateur non conecté
	 * @param CommunService
	 * @param $http
	 * @param $location
	 * @param $scope
	 * @param $timeout
	 * @returns
	 */
	function loginController(CommunService, loginService, parametersService, $http, $location, $scope, $routeParams ) {
		
		  $scope.inputType = 'password';
		  $scope.showHideClass = 'glyphicon glyphicon-eye-open';
		
		  $scope.showPassword = function(){
		   if($scope.passwordConnexion != null)
		   {
		    if($scope.inputType == 'password')
		    {
		     $scope.inputType = 'text';
		     $scope.showHideClass = 'glyphicon glyphicon-eye-close';
		    }
		    else
		    {
		     $scope.inputType = 'password';
		     $scope.showHideClass = 'glyphicon glyphicon-eye-open';
		    }
		   }
		  };
		
		
		$scope.Commun = CommunService;
		$scope.afficherPopup = CommunService.afficherPopup;
		$scope.fermerPopup = CommunService.fermerPopup;
	
		$scope.setAffichageInscription = function(affichageInscription) {
			$scope.affichageInscription = affichageInscription;
		};

		$scope.afficherPopupMotDePasseOublie = function() {
			$scope.formReinitialisationMotDePasseSubmitted = undefined;
			$('#modalMotDePasseOublie').modal('show');
		};
		
		$scope.afficherPopupCodeProvisoireOublie = function() {
			$('#modalCodeProvisoireOubliePatient').modal('show');
		};

//		$scope.parameter = parametersService.getParamById(1);
//		$scope.parameter = parametersService.getParams();
		
		$scope.logIn = function() {
			loginService.logIn($scope.mailConnexion, $scope.passwordConnexion).then(function(response) {
				// la reponse est retournée par login service
				if (response) {
					patient = response;
					if (patient.patientId) {
						// si gemni non inscrit
						if (patient.geminiNonInscrit) {
							switch (patient.geminiValidationEnCours) {
								// Codes provisoires non encore utilisés
								case 0 :
									$('#modalVerifId').modal('show');
									$scope.mailVerif = patient.mail;
									break;
								// Codes provisoires utilisés mais identité du patient en cours de validation
								case 1 :
									popupMessage = "<p>Votre identité est en cours de vérification par nos services.</p><p>Sous 24h cette vérification sera effectuée et vous recevrez un email vous indiquant que vous pouvez utiliser votre espace patient numérique.</p>";
									$scope.afficherModal("Vérification d’identité en cours", popupMessage);
									$scope.reinitialisationPage();
									break;
								//	Codes provisoires utilisés et validés. Mot de passe à définir
								case 2 :
								case 3 :
									$('#modalVerifIdValide').modal('show')
									break;
							}
						}
						// inscription non validée
						else if (patient.inscriptionValidee === false) {
							$scope.afficherModal("Compte Espace Patient Numérique non activé", "<p>Ce compte Espace Patient Numérique n’a pas encore été activé.</p><p>Pour l’activer, vous devez utiliser le lien présent dans l’email que vous avez reçu suite à l’inscription.</p>");
						} 
						// authentification ok
						else {
							$location.path('/accueil');
						}
					} 
					else {
						$scope.afficherModal("Identifiants incorrects", "Le mail ou le mot de passe est incorrect");
					}
				} 
				else {
					$scope.inscriptionMail = $scope.mailConnexion;
					// On ne redirige plus automatiquement le patient sur l'inscription pour éviter le multi compte à la place du mot de passe oublié
					//$scope.affichageInscription = true;
					$scope.authentificationErreur = true;
				}
			});
		}
	
		$scope.reinitialisationMotDePasse = function() {
			loginService.motDePasseOublieMail($scope.mailMotDePasseOublie).then(function(response) { 
				$('#modalMotDePasseOublie').modal('hide');
				if (response.data === "OK") {
					$scope.afficherModal("Vous avez oublié votre mot de passe","<img src=\"./images/pct_ok_mini.png\"/>Un mail a été envoyé à l’adresse mail "+$scope.mailMotDePasseOublie +" pour la réinitialisation du mot de passe.");
					$scope.mailMotDePasseOublieOk = $scope.mailMotDePasseOublie;
				} else if (response.data === "KO") {
					popupMessage = "Le mail "+$scope.mailMotDePasseOublie+" n'existe pas.";
					$scope.afficherModal("Vous avez oublié votre mot de passe", popupMessage);
					$scope.mailMotDePasseOublieKo = $scope.mailMotDePasseOublie;
				} else {
					$('#modalMotDePasseOubliePatient').modal('show');
					$scope.mailMotDePasseOubliePatient = $scope.mailMotDePasseOublie;
					$scope.patientMotDePasseOublie = angular.fromJson(response.data);
				}
				$scope.mailMotDePasseOublie = undefined;
				
			});
		};
	
		$scope.reinitialisationMotDePasseMulticompte = function() {
			loginService.motDePasseOublieID($scope.patientId).then(function(response) { 
				$('#modalMotDePasseOubliePatient').modal('hide');
				if (response.data === "OK") {
					$scope.afficherModal("Vous avez oublié votre mot de passe","<img src=\"./images/pct_ok_mini.png\"/>Un mail a été envoyé à l’adresse mail "+$scope.mailMotDePasseOublie +" pour la réinitialisation du mot de passe.");
					$scope.mailMotDePasseOublieOk = $scope.mailMotDePasseOubliePatient;
				} else if (response.data === "KO") {
					popupMessage = "Le mail "+$scope.mailMotDePasseOublie+" n'existe pas.";
					$scope.afficherModal("Vous avez oublié votre mot de passe", popupMessage);
					$scope.mailMotDePasseOublieKo = $scope.mailMotDePasseOubliePatient;
				}
				$scope.mailMotDePasseOubliePatient = undefined;
				
			});
		};
		
		$scope.renvoyerCodesProvisoires = function() {
			loginService.renvoyerCodesProvisoires($scope.patient).then(function(response) { 
				$('#modalCodeProvisoireOubliePatient').modal('hide');
				if (response.data === "OK") {
					console.log("OK");
/*					$scope.afficherModal("Vous avez oublié votre mot de passe","<img src=\"./images/pct_ok_mini.png\"/>Un mail a été envoyé à l’adresse mail "+$scope.mailMotDePasseOublie +" pour la réinitialisation du mot de passe.");
					$scope.mailMotDePasseOublieOk = $scope.mailMotDePasseOubliePatient;*/
				} else if (response.data === "KO") {
					console.log("KO");
/*					popupMessage = "Le mail "+$scope.mailMotDePasseOublie+" n'existe pas.";*/
/*					$scope.afficherModal("Vous avez oublié votre mot de passe", popupMessage);
					$scope.mailMotDePasseOublieKo = $scope.mailMotDePasseOubliePatient;*/
				}
			});
		}
	
		$scope.verificationIdentite = function() {
			$http({
				method: "POST",
				url: "mobile/verificationIdentite",
				data: $.param({
					"nom": $scope.nomVerif,
					"prenom": $scope.prenomVerif,
					"dateNaissance": $scope.dateNaissanceVerif,
					"mail": $scope.mailVerif,
					"login": $scope.mailConnexion,
					"password": $scope.passwordConnexion
				}),
				headers: {"Content-Type": "application/x-www-form-urlencoded"}
			})
			.then(function(response) {
				$('#modalVerifId').modal('hide');
				if (response.data === "OK") {
					$('#modalVerifIdValide').modal('show')
				} else if (response.data === "KO MAILINVALIDE") {
					$scope.mailInvalide = $scope.mailVerif;
					$scope.afficherModal("Mail invalide","Le mail <span class='error' style='padding:0'>"+$scope.mailInvalide+"</span> est invalide");
				} else if (response.data === "KO MAILEXISTANT") {
					$scope.afficherModal("Compte Espace Patient Numérique existant","Un compte Espace Patient Numérique existe déjà avec ces identifiants. Si vous souhaitez créer un espace patient numérique pour un autre membre de votre famille, vous pouvez utiliser la même adresse mail mais le mot de passe doit être différent");
				} else if (response.data === "KO") {
					$scope.mailConnexion = undefined;
					$scope.passwordConnexion = undefined;
					$scope.afficherModal("Vérification d’identité en cours","<p>Merci. Votre identité va être vérifiée par nos services sous 24h.</p><p>Une fois cette vérification effectuée, vous recevrez un email vous indiquant que vous pouvez utiliser votre espace patient numérique.</p>");
				}
				$scope.nomVerif = undefined;
				$scope.prenomVerif = undefined;
				$scope.dateNaissanceVerif = undefined;
				$scope.mail = undefined;
				$scope.formVerifId.$setPristine();
			});
		};
	
		$scope.changementMotDePasse = function(e) {
			$scope.motDePasseDejaUtilise = undefined;
			if ($scope.passwordVerif && $scope.passwordVerif2 &&
				$scope.passwordVerif.length>=6 && $scope.passwordVerif2.length>=6 &&
				$scope.passwordVerif === $scope.passwordVerif2) {
				var object = $.param({ "mail": $scope.mailConnexion, "password": $scope.passwordConnexion, "password2": $scope.passwordVerif});
				$http({
					method: "POST",
					url: "mobile/changementMotDePasse",
					data: object,
					headers: {"Content-Type": "application/x-www-form-urlencoded"}
				}).then(function(response) {
					$scope.mailConnexion = response.data.mail;
					$scope.passwordConnexion = $scope.passwordVerif;
					$('#modalVerifIdValide').modal('hide');
					// Si password changé, connexion automatique
					$scope.logIn();
				}, function(response) {
					$scope.formChangeMdp.$setPristine();
					$scope.motDePasseDejaUtilise = true;
					$scope.passwordVerif = undefined;
					$scope.passwordVerif2 = undefined;
				});
			}
		};
	
		$scope.inscription = function(e) {
			if ($scope.conditionGenerale && $scope.patient.password === $scope.patient.password2) {
				loginService.inscription($scope.patient).then(function(response) { 
					if (response.data === "OK") {
					$scope.pageIntermediaireInscription = true;
					}
					else if (response.data === "OK EXISTANT") {
						var inscriptionMail = $scope.inscriptionMail;
						$scope.reinitialisationPage();
						$scope.mailConnexion = inscriptionMail;
						$scope.afficherModal("Code provisoire et mot de passe existant","Vous pouvez vous connecter en utilisant le code provisoire et le mot de passe qui vous a été fourni par la Clinique Pasteur");
					} else if (response.data === "KO") {
						$scope.afficherModal("Compte Espace Patient Numérique existant","Un compte Espace Patient Numérique existe déjà avec ces identifiants. Si vous souhaitez créer un espace patient numérique pour un autre membre de votre famille, vous pouvez utiliser la même adresse mail mais le mot de passe doit être différent");
					} else if (response.data === "KO MAIL") {
						$scope.mailInvalide = $scope.inscriptionMail;
						$scope.afficherModal("Mail invalide","Le mail <span class='error' style='padding:0'>"+$scope.mailInvalide+"</span> est invalide");
					}
				});
			}
		};
		
		$scope.validationInscription = function() {
			var inscription = {};
			inscription.cle = $routeParams.cle;
			inscription.id = $routeParams.id;
			loginService.validationInscription(inscription).then(function(response) { 
			});
		};
	
		$scope.reinitialisationPage = function() {
			$scope.pageIntermediaireInscription = undefined;
			$scope.affichageInscription = undefined;
			$scope.patient = undefined;
			$scope.conditionGenerale = undefined;
			$scope.formInscription.$setPristine();
			$scope.mailConnexion = undefined;
			$scope.passwordConnexion = undefined;
			$scope.formAuth.$setPristine();
		};
		
		if ($routeParams.login !== null) {
			$scope.mailConnexion = $routeParams.login;
		}
	
		if ($routeParams.password !== null) {
			$scope.passwordConnexion = $routeParams.password;
		}
	
		if ($routeParams.enInscription === "true") {
			$scope.affichageInscription = true;
		}
		
		if ($routeParams.cle) {
			$scope.validationInscription();
		}
		
		$scope.openEnSavoirPlus = function (){
			$('#modalEnSavoirPlusLogin').modal('show');
		}
		
		if ($routeParams.email) {
			$scope.mailConnexion = $routeParams.email;
		}
	}

	loginController.$inject = ['CommunService', 'loginService', 'parametersService', '$http', '$location', '$scope', '$routeParams'];

	angular
	  .module('clientApp')
	  .controller('loginController', loginController);

})(angular);
