(function(angular){


	function dossierMedicalController($http,$window, $scope, CommunService, DossierMedicalService, MedecinGeneralisteService, sessionService, documentService, $location, FileUploader) {
		$scope.Commun = CommunService;
		
		// token dans le controller pour cause d'appel du server sans ajax
	 	$scope.token = sessionService.getAccessToken();
		
		$scope.demande = false;
		
		// ---------------- PARTIE DONNEES MEDICALES ----------------
		/**Demande Envoi code par sms pour l'accès aux données médicales*/
		$scope.demandeCodeSms = function() {
			$scope.demande = true;
			DossierMedicalService.demandeCodeSms();
		};
	
		/** Envoie du code reçu par sms pour l'access aux données médicales */
		$scope.submitSms = function(codeSmsUser) {
			DossierMedicalService.submitSms(codeSmsUser).then(function(response) {
				var patient = response.data;
				if(patient){
					DossierMedicalService.getTimeLines().then(function(response){
						$scope.accessDonneesMedicales = true;
						$scope.timeLines = response.data;
					});
				}
				else{
					$scope.errorCodeSms = true;
				}
				
			});
		};

		// ----- initialisation pour le dossier médical
		// on regarde si le patient a acces à son dossier médical
		$scope.initDossierMedical = function(){
			DossierMedicalService.demandeAccessDossierMedical().then(function(response){
				$scope.accessDonneesMedicales = (response.data == 'true');
				// initialisation pour l'affichage des formulaires : note et document
				$scope.showForms = {};
				$scope.showForms.note = false;
				$scope.showForms.document = false;
				
				// si acces, on recupère la timeLine
				if($scope.accessDonneesMedicales){
					DossierMedicalService.getTimeLines().then(function(response){
						$scope.timeLines = response.data;
					});
				}
			});
		}
		
		$scope.redirectionAccueil = function(){
			$location.path("accueil");
		}
		
		// ----- Retourne le détail d'une donnée médicale (intervention, résultat de labo, document) -----
		$scope.getDetailMedical = function (id, typeDetailMedical, liPast) {
			
			console.log('test timeline: ', typeDetailMedical)
			 // Dans le cas d'une intervention  : affichage du détail
			 if (typeDetailMedical === "intervention") {
				 // TODO : intervention à tester
				params = [];
				params["idDocumentMedical"] = id;
				params["liPast"] = liPast;
				$http.get("mobile/timeLine/intervention", { params : params }).then(function(response) {
					//si des données séjour sont chargées
					$("#timeline").html(response.data);
				});
				// dans le cas d'un document patient ou resultat de labo ( typeDetailMedical === "laboratoire" ) : téléchargement du document
			} else if(typeDetailMedical == "document" || typeDetailMedical === "laboratoire"){
				var win = window.open("mobile/timeLine/documentMedicale/" + $scope.token + "/?typeDetailMedical=" + typeDetailMedical + "&idDocumentMedical=" + id + "&liPast=" + liPast, "_blank");
				win.focus();	
			}
			 // document ajouté par le patient
			 else if(typeDetailMedical == "documentmedecin"){
				var win = window.open("mobile/timeLine/documentMedicaleMedecin/" +  $scope.token +  "/?idDocumentMedical=" + id , "_blank");
				win.focus();	
			}
		}
		
		$scope.supprimerDocumentPersonnel = function(nomDoc, id) {
			if (confirm("Souhaitez-vous supprimer " + nomDoc + " ?")) {
				
				$http({method: 'DELETE', url:'mobile/timeLine/suppressionDocumentPersonnel?idDocument=' + id + "&nomFichier=" + nomDoc}).then(function(response) {
					// rechargement de la timeLine
					DossierMedicalService.getTimeLines().then(function(response){
						$scope.showForms.fichier = false;
						$scope.timeLines = response.data;
					});
				});
				
			}
		};
		
		
		// initialisation du File Upload
		$scope.uploader = new FileUploader({
			url: 'mobile/pieceJointe/tmp',
			alias: 'pieceJointe',
			autoUpload: true,
			removeAfterUpload: true,
			headers: { "Authorization" : sessionService.getAccessToken() }
		});
		
		// ----- ajouter une note au dossier médical ----
		$scope.ajouterNote = function (form, note) {
			if (form.$valid) {
				DossierMedicalService.attacherNote(note).then(function(response) {
					// rechargement de la timeLine
					DossierMedicalService.getTimeLines().then(function(response){
						$scope.showForms.note = false;
						$scope.timeLines = response.data;
					});
				});
		 	}
		 }
	
		// ----- ajouter un document au dossier médical ----
		$scope.attacherDocument = function (form, fichier, docTitre, docPrive) {
			if (form.$valid) {
				DossierMedicalService.attacherDocument(fichier, docTitre, docPrive).then(function(response) {
					// rechargement de la timeLine
					DossierMedicalService.getTimeLines().then(function(response){
						$scope.showForms.fichier = false;
						$scope.timeLines = response.data;
					});
				});
			}
		}
		
		
		// TODO refaire sans jquery et sans reload 
		$("#fichierDocument").fileupload({
			dataType: "text",
			add: function (e, data) {
				$("#envoyerDocument").on("click", function () {
					if ($scope.docTitre && $scope.docNomFichier) {
						data.formData = { "titre": $scope.docTitre, "prive": $scope.docPrive, "nomFichier": $scope.docNomFichier };
						data.submit();
						$(this).parents(".timeline-ajout").hide("slow");
					}
				});
			},
			done: function (e, data) {
				$window.location.reload();
			},
			replaceFileInput: false,
			url: "mobile/ajouterDocument"
		})
		
		
		$scope.setDocNomFichier = function(element) {
			$scope.docNomFichier = element.files[0].name;
		}
	
		// ---------------------------------------------------------------------
	
		$scope.modifierTelephone = function(form, telephone){
			form.submitted = false;
			if (form.$valid) {
				DossierMedicalService.modifierTelephone(telephone).then(function(response) {
					sessionService.setUser(response.data);
					 $('#modalTelephone').on('hidden.bs.modal', function (e) {
						 $('#modalTelephonePoste').modal('show');
					  }).modal('hide');
				});
			} else {
				form.submitted = true;
			}
		};
		
		$scope.demandeLaPoste = function(){
			DossierMedicalService.demandeLaPoste();
		};
		
		$scope.refusLaPoste = function(){
			 $('#modalTelephonePoste').on('hidden.bs.modal', function (e) {
				 $scope.$apply(function(){
					 $location.path("/accueil");
			      });
			 }).modal('hide');
		}
	
		MedecinGeneralisteService.init($scope).then(function(data){
			$scope.typeaheadObject = data;
			// données affichées (mises à jour avec typeaheadObject seulement après validation de la popup)
			$scope.medecinGeneraliste = data.medecinGeneraliste;
			$scope.autorisation = data.autorisation;
		});
		
		// ----- initialisation pour liste des justificatifs administratifs
		// récupération de la liste des séjours avec les infos administratives
		$scope.initJustificatifsAdministratifs = function(){
			documentService.getListSejoursInfosAdministratifs().then(function(response){
				$scope.listSejourInfoAdministratifs = response.data;
			});
		}
		
		$scope.afficherPopupTelephone = function() {
			$("#modalTelephone").modal('show');
		}
		
		$scope.afficherPopupPoste = function() {
			$("#modalTelephonePoste").modal('show');
		}
		
	}
	
	dossierMedicalController.$inject = ['$http','$window', '$scope', 'CommunService', 'DossierMedicalService', 'MedecinGeneralisteService', 'sessionService', 'documentService', '$location','FileUploader'];

	angular
	  .module('clientApp')
	  .controller('DossierMedicalController', dossierMedicalController);

})(angular);