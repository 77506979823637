(function(angular){
		
	/**
	 *  Filter capitalize
	 *  @param input est un string
	 */
	function capitalizeFilter(){
		return function(input) {
			if (input != null) {
				return input.substr(0,1).toUpperCase() + input.substr(1);
			}
			return null;
		}
	}
		
	capitalizeFilter.$inject = [];
		
	angular.module('clientApp')
		.filter('capitalize', capitalizeFilter);

})(angular);