(function(angular){
		
	/**
	 *  Filter pour affichage html
	 */
	function trustHtmlFilter($sce){
		return function() {
			return $sce.trustAsHtml;
		}
	}
		
	trustHtmlFilter.$inject = ['$sce'];
		
	angular.module('clientApp')
		.filter('trustAsHtml', trustHtmlFilter);

})(angular);