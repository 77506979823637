(function(angular){
		
	/**
	 * Directive prenant un texte et des arguments et remplace les placeholders par les arguments
	 * @returns
	 */
	function argumentDirective($sce){
		  return{
			  restrict:'E',
			  template: '<span ng-bind-html="textReplace"></span>',
			  scope:{
				  mainText: '@',
				  arguments: '='
			  },
			  
			  link: function(scope, elem, attrs) {
				  
				  scope.$watch("mainText",function(newValue,oldValue) {
					  scope.textReplace = scope.mainText;
					  
					  //on parcourt les paramètres et on remplace les accolates {n} par l'ordre du paramètre
					  for(var i=0; i < scope.arguments.length; i++){
						  scope.textReplace = scope.textReplace.replace("{" + i + "}", scope.arguments[i]);
					  }
					  scope.textReplace = $sce.trustAsHtml(scope.textReplace);
				    });
			  }
		  }
	}
		
	argumentDirective.$inject = ['$sce'];
		
	angular.module('clientApp')
		.directive('argumentDirective', argumentDirective);

})(angular);