(function(angular){

	function patientService($http){
			
		this.updatePatient = function(patient){
			return $http({method: 'POST', url: 'mobile/update-patient', data: JSON.stringify(patient)});
		}
		
		/** Retourne données médicales : prochain séjour*/
		this.getDonneMedicalesProchainSejour = function(){
			return $http({method: 'GET', url: 'mobile/donnees-medicales-prochain-sejour'});
		}
		
		/** Retourne données médicales : séjour avant prochain séjour*/
		this.getDonneMedicalesSejourAvant = function(){
			return $http({method: 'GET', url: 'mobile/donnees-medicales-sejour-avant'});
		}
		
		/** Retourne données médicales : séjour à partir de son id*/
		this.getDonneMedicalesSejour= function(sejourId){
			return $http({method: 'GET', url: 'mobile/donnees-medicales-sejour/' + sejourId });
		}
		
		/** Mets à jour le patient / sejour de la pre-admission */
		this.updatePatientPreadmission = function(patientPreadmission){
			return $http({method: 'POST', url: 'mobile/update-patient-preadmission', data: JSON.stringify(patientPreadmission)});
		}
		
		/** Envoi le dossier de pre-admission */
		this.validePatientPreadmission = function(patientPreadmission){
			return $http({method: 'POST', url: 'mobile/validation-patient-preadmission', data: JSON.stringify(patientPreadmission)});
		}
		
		/** Mets à jour le flag de la demande de copie pour la pre-admission */
		this.updateFlagCopiePreadmission = function(patientPreadmission){
			return $http({method: 'PUT', url: 'mobile/update-flag-copie-preadmission', data: JSON.stringify(patientPreadmission)});
		}
		
		this.getURLChambreIndividuelle = function(sejour){
			return $http.post('mobile/getURLChambreIndividuelle', sejour.sejourId);
		} 
		
		this.updateEtapeConsentementSigne = function(id){
			return $http.post('mobile/circuit/updateEtapeConsentementSigne', id);
		} 
		
		this.getInfoPaiementChambreIndividuelle = function(sejourId){
			return $http.post('mobile/info-paiement-chambre-individuelle', sejourId);
		} 
		
		this.gestionRetourPaiementChambreIndividuelle = function(retourPaiement){
			return $http.post('mobile/gestion-retour-paiement-chambre-individuelle', retourPaiement);
		} 
		
	}

	patientService.$inject = ['$http'];

	angular
	    .module('clientApp')
	    .service('patientService', patientService);
	
})(angular);