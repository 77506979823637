(function(angular){

	/** Controller pour la page de redirection vers la page de l'application mobile correspondante */
	function applicationController( $scope, $routeParams) {
		
		$scope.redirectOnUserAgent = function() {
			var mapping = new Array();

			mapping["CP-Guidage"] = {
					"android": "https://play.google.com/store/apps/details?id=com.appsfit.android",
					"iphone": "https://itunes.apple.com/app/id574406974"
			}

			mapping["CP-Famille"] = {
					"android": "https://play.google.com/store/apps/details?id=com.espace.famille",
					"iphone": "https://itunes.apple.com/fr/app/cp-famille/id1076530666?mt=8"
				}

			mapping["YouTube"] = {
				"android": "https://play.google.com/store/apps/details?id=com.google.android.youtube",
				"iphone": "https://itunes.apple.com/app/id1076530666"
			}
			
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if (/android/i.test(userAgent)) {
				$scope.telNonReconnu = "android";
		    }

		    // iOS detection from: http://stackoverflow.com/a/9039885/177710
		    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		    	$scope.telNonReconnu = "iphone";
		    }
			
			if($scope.telNonReconnu) {
				var url = mapping[$routeParams.appli][$scope.telNonReconnu];
				window.location = url;
			} else {
				$scope.telNonReconnu = "Téléphone non reconnu";
			}
		}

	}

	applicationController.$inject = [ '$scope', '$routeParams'];
	
	angular
	  .module('clientApp')
	  .controller('applicationController', applicationController);

})(angular);