(function(angular){

	/**
	 * Controller utilisé pour la page d'accueil
	 */
	function accueilController( $scope, $rootScope, $filter, CommunService, parametersService, 
								AccueilService, MedecinGeneralisteService, PersonneAPrevenirService, 
								circuitService, patientService, programmeSanteService, 
								sessionService, documentService, LIENS_PATIENT, $routeParams, $location, $http, $sce, CODE_VISIBLE_SI) {
		$scope.Commun = CommunService;
		$scope.token = sessionService.getAccessToken();
		
		$scope.afficherPopupAvis = function() {
			$scope.avis = { page : "Accueil"};
			$('#modalAvisInterne').modal('show');
		}
		
		$scope.afficherPopupProbleme = function() {
			$scope.probleme = { page : "Accueil"};
			$('#modalProbleme').modal('show');
		}
	
		$scope.avisSubmit = function() {
			AccueilService.creerAvis($scope.avis).then(function(response) {
				$('#modalAvisInterne').modal('hide');
				$scope.afficherModal("Mon avis sur le site", "Votre avis a été soumis au service informatique de la Clinique Pasteur.");
			});
		}
		
		$scope.trustAsHtml = function(string) {
		    return $sce.trustAsHtml(string);
		};
		
		$scope.problemeSubmit = function(form){
			form.submitted = false;
			if(form.$valid){
				AccueilService.creerProbleme($scope.probleme).then(function(data) {
					$scope.probleme = undefined;
					form.submitted = false;
					$('#modalProbleme').modal('hide');
					$scope.afficherModal("Je rencontre un problème et je souhaite être aidé", "Votre problème a été soumis au service informatique de la Clinique Pasteur.");
			 	});
			}else{
				form.submitted = true;
			}
		}
		
		$scope.afficherFichePersonneAPrevenir = function() {
			$scope.personneAPrevenir = undefined;
			$('#modalFichePrevenir').modal('show');
		}
		
		/* ***************** Partie circuit ***************** */
		$scope.updateEtape = function(wrapperEtapeCourante){
			// datepicker pour le parent transformée en date
			if(wrapperEtapeCourante.etapeCourante.parentModifiable){
				if (wrapperEtapeCourante.etapeCourante.parent.dateJour) {
					// Récupération de la date
					wrapperEtapeCourante.etapeCourante.parent.date = new Date(wrapperEtapeCourante.etapeCourante.parent.dateJour);
					// Ajout des heures
					wrapperEtapeCourante.etapeCourante.parent.date.setHours(wrapperEtapeCourante.etapeCourante.parent.dateHeure.getHours());
					wrapperEtapeCourante.etapeCourante.parent.date.setMinutes(wrapperEtapeCourante.etapeCourante.parent.dateHeure.getMinutes());
					
					$scope.errorPopupEtape = false;
					// si date non valide, popup non validé
					if(isNaN(wrapperEtapeCourante.etapeCourante.parent.date.valueOf())){
						$scope.errorPopupEtape = true;
						wrapperEtapeCourante.etapeCourante.parent.date = null;
					}
				}
			}
			
			// date le cas de prise de connaissance de documents
			if(wrapperEtapeCourante.checkConsentement){
				$scope.errorPopupEtape = !wrapperEtapeCourante.consentement;
			}
			
			// si il y a une validation côté clinique : le statut passe en ATTENTE
			if(wrapperEtapeCourante.etapeCourante.validationCP){
				wrapperEtapeCourante.etapeCourante.statut = 'ATTENTE';
			}
			// pas d'update si error de validation
			if(!$scope.errorPopupEtape){
				circuitService.updateEtape(wrapperEtapeCourante.etapeCourante).then(function(response){
					$('#modalEtape').modal('hide');
					$scope.chargerCircuits();
				});
			}
		}
		
		/** Maj d'un patient : affichEnsuite, affichHistorique **/
		$scope.updatePatient = function(){
			patientService.updatePatient(sessionService.getUser()).then(function(response){
				sessionService.setUser(response.data);
			});
		}
		
		/** Reset du document sélectionné et de la liste des upload dans le cas d'une étape d'upload **/
		$scope.resetDocument = function(){
			$('#fileInputDocument').val('');
			$scope.documents = [];
		}
		
		/**
		 * Récupération des circuits
		 */
		$scope.chargerCircuits = function(){
			// récupération des circuits du patient et des étapes
			circuitService.getCircuits().then(function(response){
				$scope.circuits = response.data;
				$scope.wrapperEtapeCourante = {};
			});
		}
		
		/**
		 * Indique si l'étape est à faire maintenant
		 * Date de l'etape dans les bons intervalles de date
		 * ET
		 * pas de visibleSi sans Date de validation
		 * 
		 * AJOUT : si une etape est en attente, elle est affichée dans les etapes "maintenant"
		 * @param etape
		 */
		$scope.etapeIsMaintenant = function(etape){
			var dateJour = new Date();
			dateJour.setHours(0);
			
			var maintenant = false;
			// si il y a une date, doit être avant dateJour
			if(etape.date) {
				var dateEtape = new Date(etape.date);
				dateEtape.setHours(0);
				maintenant = dateEtape <= dateJour;
			}
			// sinon borne
			else{
				// dateJour >= borne inferieure
				if(etape.dateMin){
					var dateInf = new Date(etape.dateMin);
					dateInf.setHours(0);
					maintenant = dateInf <= dateJour;
				}
				// on ne verifie pas la date superieure
				else{
					maintenant = true;
				}
			}
			return maintenant && !(etape.visibleSi && !etape.visibleSi.dateValidation);
		}
		
		
		/**
		 * Retourne les etapes filtrées
		 */
		function getEtapesFiltre(etape, affichHistorique, affichEnsuite){
			// on si affichEnsuite on prend toutes les etapes (hormis validées)
			var maintenant = affichEnsuite;
			if(!affichEnsuite){
				maintenant = $scope.etapeIsMaintenant(etape);
			}
			
			// si etape en attente à afficher tout le temps
			var attente = etape.validationCP && etape.dateValidation && etape.statut == 'ATTENTE';
			
			// retourne l'etape si maintenant (ou affichEnsuite)
			// et affichHistorique ( ou date validation)
			return !etape.masquee && (attente || (maintenant && !etape.dateValidation) || (affichHistorique && etape.dateValidation));
		}
		
		/**
		 * Filtre les etapes obligatoires
		 * @param affichHistorique si vrai affichage etapes réalisées
		 * @param affichEnsuite si vrai affichage étapes à venir
		 */
		$scope.filterEtape = function(affichHistorique, affichEnsuite) {
			return function(etape){
				return getEtapesFiltre(etape, affichHistorique, affichEnsuite);
			}
		}
		
		/**
		 * Upload d'un document pour une etape patient
		 */
		$scope.uploadDocuments = function(){
			var traite = 0;
			for(var i=0; i < $scope.documents.length; i++){
				var document = $scope.documents[i];
				documentService.uploadDocument(document, $scope.wrapperEtapeCourante.etapeCourante.id, "PERSO").then(function (response) {
					traite +=1;
					// pour le dernier document : fermeture popup / arret loader / chargement des circuits
					if(traite === $scope.documents.length){
						$('#modalEtape').modal('hide');
						//$scope.chargerCircuits();
						$scope.updateEtape($scope.wrapperEtapeCourante);
					}
		        });
			}
		}
		
		/**
		 * Ajout document
		 */
		$scope.addDocument = function(document, tailleMaxi){
			if(document && !tailleMaxi){
				$scope.documents.push(document);
			}
		}
		
		/**
		 * Suppression document (qui vient d'être ajouté)
		 */
		$scope.removeDocument = function(document){
			var index = $scope.documents.indexOf(document);
			if (index > -1) {
				$scope.documents.splice(index, 1);
			}
		}
		
		/**
		 * Suppression document existant
		 */
		$scope.deleteDocument = function(document){
			var documents = $scope.wrapperEtapeCourante.etapeCourante.documents;
			var index = documents.indexOf(document);
			if (index > -1) {
				documentService.deleteDocument(document.id).then(function (response) {
					documents.splice(index, 1);
				});
			}
		}
		
		/**
		 * Affichage de la popup sur les etapes UPLOAD si pas de document
		 */
		$scope.checkPasDeDocument = function(){
			if ($scope.wrapperEtapeCourante.etapeCourante.pasDeDocument && $scope.wrapperEtapeCourante.etapeCourante.facultative == 0) {
				$scope.afficherModal("Attention", "Ce document est trés important! Son absence peut remettre en cause votre séjour. Si vous n’avez pas ce document en votre possession alors que vous devriez, merci de le récupérer au plus vite. Dès réception, vous devrez nous le transmettre via votre Portail Patient, afin que nous puissions compléter votre dossier.", true);
			
			}
			if ($scope.wrapperEtapeCourante.etapeCourante.pasDeDocument && $scope.wrapperEtapeCourante.etapeCourante.facultative == 2) {
				$scope.afficherModal("Attention", "Ce document est important! Si vous n’avez pas ce document en votre possession alors que vous devriez, merci de le récupérer au plus vite. Dès réception, vous pourrez nous le transmettre via votre Portail Patient, afin que nous puissions compléter votre dossier.", true);
			}
		}
		
		/**
		 * Redirection en cas de lien dans l'étape
		 */
		$scope.redirectionEtape = function(lien){
			 $('#modalEtape').on('hidden.bs.modal', function (e) {
				 $scope.$apply(function(){
			           $location.path(lien);
			      });
			  }).modal('hide');
		}
		
		$scope.initChampSaisieValeur = function (){
			// initialisation 
			$scope.champSaisieValeurs = [];
			$scope.dateMoment = null;
			$scope.dateSaisie = null;
		}
		
		
		/**
		 * Validation et appel webservices pour la creation des constantes
		 * @param isEtape : si vrai champ de saisie depuis étape
		 */
		$scope.creerChampSaisieValeurDonneesMedicales = function(moment, dateSaisie, isEtape){
			// date de mesure
			var dateMesure = new Date(); // maintenant
			if(moment === 'matin'){
				dateMesure.setHours(8);
			}
			else if(moment === 'apres-midi'){
				dateMesure.setHours(14);
			}
			else if(moment === 'autre'){
				dateMesure = new Date(dateSaisie);
			}
			
			var champSaisieValeurs = CommunService.getChampSaisieValeurListe($scope.champSaisieValeurs, dateMesure, $scope.wrapperEtapeCourante.etapeCourante.id );
			$scope.formInvalide = (champSaisieValeurs == null);
			// si le formulaire est valide
			if(!$scope.formInvalide){
				programmeSanteService.createChampSaisieValeur(champSaisieValeurs).then(function(response){
					// si il y a une validation côté clinique : le statut passe en ATTENTE
					if($scope.wrapperEtapeCourante.etapeCourante.validationCP){
						$scope.wrapperEtapeCourante.etapeCourante.statut = 'ATTENTE';
					}
						
					// calcul de l'expression des conditions pour l'affichage d'une alerte
					var conditions = $scope.wrapperEtapeCourante.etapeCourante.etape.evenementConditions;
					var expression = '';
					for(var i=0; i < conditions.length; i++){
						// var etapeChampSaisie = $filter('filter')( etapePatientChampSaisies, { champsaisie:{ code: conditions[i].code }}, true)[0];
						var valeurChamp = $scope.champSaisieValeurs[conditions[i].champsaisieCode].valeur;
						var valeurAlerte = conditions[i].champsaisieValeur;
						if (isNaN(valeurChamp)) {
							valeurChamp = " '" + valeurChamp + "' ";
						}
						if (isNaN(valeurAlerte)) {
							valeurAlerte = " '" + valeurAlerte + "' ";
						}
						expression += valeurChamp + conditions[i].operateur + valeurAlerte ;
						if(i < conditions.length - 1){
							//expression += conditions[i].typeCondition == "ET" ? "&&" : "||";
							expression += " || ";
						}
					}
					// si expression vraie alors affichage de l'alerte
					var evalExpression = eval(expression.toUpperCase());
					if(evalExpression){
						$scope.wrapperEtapeCourante.etapeCourante.alerte = "ALERTE";
					}
						
					circuitService.updateEtape($scope.wrapperEtapeCourante.etapeCourante).then(function(response){
						$('#modalEtape').modal('hide');
						$scope.chargerCircuits();
					});
				});
			}
		}
		
		/**
		 * initialisation date picker
		 */
		$scope.initDatePicker = function(idInput){
			$.datetimepicker.setLocale("fr");
			$("#datetimepicker" + idInput).datetimepicker({
				//format: "Y-m-d H:i",
				step: 30
			});
		}
		
		/**
		 * Retourne vrai si le questionnaireChampSaisie est visible
		 */
		$scope.isVisibleChampSaisie = function( questionnaireChampSaisie, questionnaire){
			// si pas de champs visibles : tous les questionnaireChampSaisies du questionnaire sont visibles
			if(!questionnaire.champVisibles || !questionnaire.champVisibles.length){
				return true;
			}

			// sinon : on filtre pour voir si le questionnaireChampSaisie à un champVisible associé
			var champVisibles = $filter('filter')( questionnaire.champVisibles, { champSaisieVisibleSi : { id: questionnaireChampSaisie.champSaisie.id}}, true);
			// si non, on retourne le champ
			if(!champVisibles || !champVisibles.length){
				return true;
			}

			// si oui, on verifie la valeur du champ condition
			// pour le moment, on part sur un seul champ visible associé
			var champVisible = champVisibles[0];

			// valeur de la constante (du champ de saisie ou du patient)
			var valeurConstante;
			// 1- cas condition avec champ de saisie
			if(champVisible.champSaisieCondition){
				// récupération du questionnaire champ saisie associé au  champ saisie condition
				var questionnaireChampSaisieCondition =  $filter('filter')( questionnaire.questionnaireChampSaisies, { champSaisie : { id: champVisible.champSaisieCondition.id}}, true)[0];
				// récupération de la valeur du champ de saisie
				var champSaisieValeur = $scope.champSaisieValeurs[questionnaireChampSaisieCondition.champSaisie.code];

				// si la valeur verifie la condition, on affiche le questionnaire champ de saisie
				if(!champSaisieValeur || !champSaisieValeur.valeur){
					return false;
				}
				valeurConstante = champSaisieValeur.valeur
			}
			// 2- cas condition avec code condition
			else{
				// cas CIVILITE PATIENT (cas unique pour le moment)
				if(champVisible.codeVisibleSi === CODE_VISIBLE_SI[0] && sessionService.getUser()){
					valeurConstante = sessionService.getUser().civilite;
				}
			}

			// true si visible
            var visible

			// 1- Cas de valeur simple ( pas de MULTI_LISTE)
			if( !champVisible.champSaisieCondition || champVisible.champSaisieCondition.entreeType !== 'MULTI_LISTE' ){
				// retourne l'evaluation en fonction de la valeur de la constante, de l'operateur et de la valeur du champ visible
				// ajouter les guillemets pour les valeurs alpha
				if (isNaN(valeurConstante)) {
					valeurConstante = " '" + valeurConstante + "' ";
				}
				var valeurCondition = champVisible.valeurCondition;
				if (isNaN(valeurCondition)) {
					valeurCondition = " '" + valeurCondition + "' ";
				}
				visible =   eval(  valeurConstante  + champVisible.operateur  + valeurCondition );

			}
			// 2- Cas de champ de saisie 'MULTI_LISTE' (separation par des pipes)
			// ici valeurConstante est un tableau
			else{
				// valurs pour l'affichage de la condition séparées par des pipes |
				var valeurs = champVisible.valeurCondition.split("|");
				
				// Parcours des valeurs entrées par le user
				for(var i=0; i < valeurConstante.length; i++){
					var valeurUser = valeurConstante[i];
					for(var j=0; j < valeurs.length; j++){
						// si une des valeurs est égale alors visible = true (fonctionne comme des OU )
						if( valeurs[j] == valeurUser) {
							visible = true;
						}
					}
					if(visible){
						break;
					}
				}
			}

			// Dans le cas où ce ne soit pas visible, suppression du champ de saisie dans champSaisieValeurs
			if(!visible){
				delete $scope.champSaisieValeurs[questionnaireChampSaisie.champSaisie.code];
			}
			return visible;
		}

		/* ***************** Fin circuit ***************** */
		
		/* ************** Choix du rappel d'activité ************* */
		$scope.setRappelActivite = function(rappelActivite) {
			AccueilService.setRappelActivite(rappelActivite);
		}
		
		/* Personne à prevenir */
		$scope.enregistrerPersonneAPrevenir = function(form){
			if(form.$valid){
				$scope.personneAPrevenirObject.dialogFichePersoPrevenirSubmit(form);
				$scope.personneAPrevenir = null;
			}
			else{
				form.submitted = true;
			}
		}
		
		$scope.init = function() {
			
			if ($routeParams.appli === "mobile") {
				$scope.setMobileApp();
			}
			
			// Gestion du retour de la signature électronique pour l'envoi des consentements éclairés
			if ($routeParams.mode === "SIGN") {
				if ($routeParams.userAction === "OK") {
					console.log(JSON.stringify($routeParams));
					circuitService.updateEtapeConsentementSigne($routeParams.id, $routeParams.transactionID).then(function(response){
						$scope.messageRetourConsentement = "Votre consentement a bien été signé électroniquement. Vous pouvez désormais fermer cette fenêtre."
					});
					
				} else {
					$scope.messageRetourConsentement = "La signature du consentement a été annulée."
					console.log(JSON.stringify($routeParams));
				}
				
				
			}
			
			// Récupération URL pour la télé-consultation
			AccueilService.getUrlTeleConsultation().then(function(response) {
				var url = response.data;
				if (url) {
					$scope.urlTeleConsultation = url + "?token=" + $scope.token.replace('Bearer ','') ;
				}
			});
			
			PersonneAPrevenirService.init($scope).then(function(data){
				$scope.personneAPrevenirObject = data;
			});
			
			AccueilService.getAllContactMedicals(true, true, null).then(function(response) {
				$scope.contactMedicals = response.data;
			});
			
			// chargements des circuits
			$scope.chargerCircuits();
			
			// initialisation des sejours prochain et avant
			if(sessionService.getUser() && sessionService.getUser().sejours){
				$scope.sejourEnCours = $filter('filter')( sessionService.getUser().sejours, { sejourId: sessionService.getProchainSejourId()}, true)[0];
				var sejours = $filter('orderBy')( sessionService.getUser().sejours, 'dateEntree', true);
				
				// pour avoir le séjour avant on trie les sejours par date entree et on ajoute les sejour d'après
				var index = sejours.indexOf($scope.sejourEnCours);
				if(index != -1 && sejours[index + 1]){
					$scope.sejourAvant = sejours[index + 1];
				}
			}
				
			// chargements des données médicales
			// TODO : mettre un cache
			patientService.getDonneMedicalesProchainSejour().then(function(response){
				$scope.donneesMedicalesSejour = response.data;
				// appel pour le sejour d'avant
				patientService.getDonneMedicalesSejourAvant().then(function(response){
					$scope.donneesMedicalesSejourAvant = response.data;
					// calcul de l'etat du sejour : present / prevu / sorti
					// CAS PRÉSENT
					if(($scope.donneesMedicalesSejour && $scope.donneesMedicalesSejour.present) || ($scope.donneesMedicalesSejourAvant && $scope.donneesMedicalesSejourAvant.present)){
						$scope.etatSejourPasteur = "present";
					}
					// CAS PRÉVU
					else if($scope.donneesMedicalesSejour && $scope.donneesMedicalesSejour.prevu){
						$scope.etatSejourPasteur = "prevu";
					}
					// CAS SORTI
					else if(($scope.donneesMedicalesSejour!=null && $scope.donneesMedicalesSejour.sorti) || ($scope.donneesMedicalesSejourAvant!=null && $scope.donneesMedicalesSejourAvant.sorti)){
						$scope.etatSejourPasteur = "sorti";
					}
				});
			});
			
			$scope.liensPatient = LIENS_PATIENT;
			
			$scope.documents = [];
			
			CommunService.getNotifsPatient();
			CommunService.showLastUnreadNotif();
		}
		
		$scope.init();
	}

	accueilController.$inject = [	'$scope', '$rootScope', '$filter', 'CommunService', 'parametersService',
									'AccueilService', 'MedecinGeneralisteService', 'PersonneAPrevenirService',
									'circuitService', 'patientService', 'programmeSanteService', 'sessionService',
									'documentService', 'LIENS_PATIENT', '$routeParams', '$location', '$http', '$sce', 'CODE_VISIBLE_SI'];

	angular
	  .module('clientApp')
	  .controller('AccueilController', accueilController);

})(angular);