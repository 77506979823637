(function(angular){
	
	/**
	 * Controller parent de l'application (avec gestion du menu connecté)
	 */
	function parentController( $http, $location, $scope, CommunService, sessionService) {
		
		$scope.popupAnciennesDatesActif = false;
		$scope.Commun = CommunService;

		// Formats pour Date picker
		$scope.format = 'dd/MM/yyyy';
		$scope.altInputFormats = ['dd-MM-yyyy', 'd!-M!-yyyy', 'd!/M!/yyyy'];
		
		$scope.afficherPopup = CommunService.afficherPopup;
		$scope.fermerPopup = CommunService.fermerPopup;
		
		$scope.mobile = false;
		
		$scope.setMobileApp = function() {
			$scope.mobile = true;
		}
	
		$scope.affichMaskFichePatient = function() {
			$scope.fichePatientMiseAJour = undefined;
			$scope.displayFichePatient = !$scope.displayFichePatient;
			if ($scope.displayFichePatient && $scope.displayNotifsPatient){
				$scope.displayNotifsPatient = !$scope.displayNotifsPatient;	
			}
			
			if(!$scope.displayFichePatient){
				$("#zone-patient-titre").addClass("collapse-fiche");
				$("#zone-patient-titre").removeClass("expand-fiche");
			}
			else{
				$("#zone-patient-titre").removeClass("collapse-fiche");
				$("#zone-patient-titre").addClass("expand-fiche");
			}
		}
	
		$scope.affichMaskNotifsPatient = function() {
			$scope.displayNotifsPatient = !$scope.displayNotifsPatient;
			if ($scope.displayNotifsPatient && $scope.displayFichePatient){
				$scope.displayFichePatient = !$scope.displayFichePatient;
				$("#zone-patient-titre").addClass("collapse-fiche");
				$("#zone-patient-titre").removeClass("expand-fiche");
			}			
		}
		
		// gestion des erreurs
		$scope.$on("EspacePatientError", function(e, messageData) {
			$scope.popupMessage = messageData;
			$('#modalError').modal('show');
		});
	
		CommunService.afficherPopupMedecinGeneraliste = function() {
			if (CommunService.departementMedecin) {
				var select = $("#departement");
				var option = $("option[value^='?']", select);
				option.remove();
				select.val(CommunService.departementMedecin);
			}
			CommunService.afficherPopup("popupMedecinGeneraliste");
		};
	
		CommunService.fermerPopupMedecinGeneraliste = function() {
			CommunService.fermerPopup("popupMedecinGeneraliste");
		};
	
		
		/**
		 * Indique si l'element du menu est selectionné
		 * @param path : chemin courant au niveau donné
		 * @param niveau : niveau de profondeur 
		 */
		$scope.isMenuActifRefactor = function(path, niveau){
			var menuActif = false;
			var pathCourant = $location.path().substr(1, $location.path().length);
			var paths = pathCourant.split("/");
			if(paths.length > (niveau) && paths[niveau] === path){
				menuActif = true;
			}
			return menuActif;
		}
		
		/**
		 * Retourne la classe d'un element du menu en fonction du path et du niveau de profondeur
		 * @param path : chemin courant au niveau donné
		 * @param niveau : niveau de profondeur 
		 */
		$scope.getMenuActiveClass = function(path, niveau){
			return $scope.isMenuActifRefactor(path, niveau) ? "menuActif" : "menuInactif";
		}
		
		/**
		 * Ouverture des sous menus pour mobile
		 */
		$scope.clicMenu = function(typeMenu){
			$scope.typeSousMenu = null;
			if($scope.typeMenu != typeMenu){
				$scope.typeMenu = typeMenu;
			}
			else{
				$scope.typeMenu = null;
			}
		}
		
		/**
		 * Ouverture des sous/sous menus pour mobile
		 */
		$scope.clicSousMenu = function(typeSousMenu){
			if($scope.typeSousMenu != typeSousMenu){
				$scope.typeSousMenu = typeSousMenu;
			}
			else{
				$scope.typeSousMenu = null;
			}
		}
		
		CommunService.afficherFichePatient = function() {
			$scope.patientAModifier = {};
			$scope.patientAModifier.civilite = sessionService.getUser().civilite;
			$scope.patientAModifier.nom = sessionService.getUser().nom;
			$scope.patientAModifier.nomJeuneFille = sessionService.getUser().nomJeuneFille;
			$scope.patientAModifier.prenom = sessionService.getUser().prenom;
			$scope.patientAModifier.dateNaissance = sessionService.getUser().dateNaissance;
			$scope.patientAModifier.adresse = sessionService.getUser().adresse;
			$scope.patientAModifier.codePostal = sessionService.getUser().codePostal;
			$scope.patientAModifier.ville = sessionService.getUser().ville;
			$scope.patientAModifier.telephonePortable = sessionService.getUser().telephonePortable;
			$scope.patientAModifier.telephoneFixe = sessionService.getUser().telephoneFixe;
			$scope.patientAModifier.mail = sessionService.getUser().mail;
			
			$scope.changerMotDePasseActif = false;
			$('#modalFichePatient').modal('show');
		};
		
		CommunService.afficherNotifPatient = function(notifPatient) {
			$scope.notificationPatient = {};
			$scope.notificationPatient.titreNotif = notifPatient.titreNotif;
			$scope.notificationPatient.payloadNotif = notifPatient.payloadNotif;
			$scope.notificationPatient.dateCreation = new Date (notifPatient.dateCreation);
			$scope.notificationPatient.patientId = notifPatient.patientId; 
			$scope.notificationPatient.status = notifPatient.status;
			$('#modalNotifPatient').modal('show');
			CommunService.readNotifsPatient(notifPatient);
		};
		
		
		CommunService.afficherLastNotifPatient = function(notifsPatient) {
			if ($scope.lastNotifsPatient.length>0){
				$scope.notificationPatient = {};
				$scope.notificationPatient.titreNotif = lastNotifsPatient[0].titreNotif;
				$scope.notificationPatient.payloadNotif = lastNotifsPatient[0].payloadNotif;
				$scope.notificationPatient.dateCreation = lastNotifsPatient[0].dateCreation;
				$scope.notificationPatient.patientId = lastNotifsPatient[0].patientId; 
				$scope.notificationPatient.status = lastNotifsPatient[0].status;
				$('#modalNotifPatient').modal('show');
			}
		};
		
		
		CommunService.setTelPortableVerifie = function(telPortableVerifie) {
			CommunService.telPortableVerifie = telPortableVerifie;
		};
		
		
		/**
		 * TODO : supprimer
		 * Permet d'avoir le sejourId accessible dans les controleurs enfants
		 */
		$scope.openMenuHospit = function(sejourId, path) {
			// sejourId : accessible dans l'ensemble de l'appli
			sejourId = !sejourId ? 0 : sejourId;
			
			CommunService.sejourId = sejourId;
			if(!path){
				path = "cequejedoissavoir";
			}
			$location.path("/hospitalisations/" + path + "/" + sejourId);
		}
		
		/**
		 * TODO : a modifier
		 */
		CommunService.deconnecterPatient = function() {
			$scope.displayFichePatient = false;
			$http.get('mobile/deconnexion');
			sessionService.destroy();
			$location.path("/");
		}

		CommunService.getNotifsPatient = function() {
			$http.get('mobile/get-notifications-patient').then(function(response) {
				$scope.notifsPatient = response.data;
				return response.data;
			});
		}
		
		CommunService.showLastUnreadNotif = function () {
			$http.get('mobile/get-notifications-patient').then(function(response) {
				$scope.notifsPatient = response.data;
				if ($scope.notifsPatient.length > 0  && $scope.notifsPatient[0].status == "NEW"){
					CommunService.afficherNotifPatient($scope.notifsPatient[0]);
				} else {
					console.log("Pas de nouvelle notif");					
				}
			});
		}
		

		CommunService.getLastNotifsPatient = function() {
			var promise = $http.get('mobile/get-last-notifications-patient').then(function(response) {
				$scope.lastNotifsPatient = response.data;
				return response.data;
			});
		}
		
		
		CommunService.readNotifsPatient = function(notif) {
			if (notif.status == "NEW"){
				var promise = $http.put('mobile/read-notifications-patient', notif)
				.then(function(response) {
					console.log("CommunService - readNotifsPatient : "+ response.data);
					CommunService.getNotifsPatient();
					$('#modalNotifPatient').modal('show');
				});
			}
			// else : deja lue
		}		

		/**
		 * TODO : a modifier
		 */
		CommunService.validerFichePatient = function(patientAModifier) {
			// validation du formulaire
			var valide = $("#fiche-patient-form").valid();
	 
			if (valide) {
				// Transformation des numeros au format international
				var telFixeForm = $("#telephoneFixeFicheForm");
				if (telFixeForm.intlTelInput("getSelectedCountryData").iso2 !== "fr") {
					patientAModifier.telephoneFixe = telFixeForm.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
				}
	
				// Transformation des numeros au format international
				var telPortableForm = $("#telephonePortableFicheForm");
				if (telPortableForm.intlTelInput("getSelectedCountryData").iso2 !== "fr") {
					patientAModifier.telephonePortable = telPortableForm.intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
				}
				if (telPortableForm.val() && telPortableForm.val().length > 0) {
					$("#rappelActiviteSms").attr("disabled", false);
				} else {
					$("#rappelActiviteSms").attr("disabled", true);
				}
				
				// probleme pour les dates à partir de 1975 et pendant les heures d'été (arrive avec un jour de moins car 1h en moins)
				// mise de l'heure à 12 pour ne pas avoir le problème... TODO factoriser avec pread
				if($scope.patientAModifier.dateNaissance){
					$scope.patientAModifier.dateNaissance = new Date($scope.patientAModifier.dateNaissance);
					$scope.patientAModifier.dateNaissance.setHours(12);
				}
				
				$http.post('mobile/update-fiche-patient', JSON.stringify(patientAModifier)).then(function(response) {
					$scope.fichePatientMiseAJour = true;
					// maj de la session
					sessionService.setUser(response.data);
					$scope.changerMotDePasseActif = false;
					$scope.fichePatientMiseAJour = undefined;
					$('#modalFichePatient').on('hidden.bs.modal', function (e) {
						 $scope.$apply(function(){
							 $scope.affichMaskFichePatient();
					         $location.path("/accueil");
					      });
					  }).modal('hide');
				});
			}
		};
		
		/**
		 * Retourne vrai si la chaîne date passée en paramètre est après la date du jour
		 * TODO : créer filtre ou directive
		 */
		$scope.dateApresJour = function(dateString){
			return (dateString && new Date(dateString) < new Date());
		}
		
		$scope.afficherModal = function(titre, contenu){
			$scope.popupTitle = titre;
			$scope.popupMessage = contenu;
			$scope.displayFooter = false;
			$('#modalGenerique').modal('show');
		}
		
		$scope.afficherModal = function(titre, contenu, displayFooter){
			$scope.popupTitle = titre;
			$scope.popupMessage = contenu;
			$scope.displayFooter = displayFooter;
			$('#modalGenerique').modal('show');
		}
	}

	parentController.$inject = ['$http', '$location', '$scope', 'CommunService', 'sessionService'];
	
	angular
	  .module('clientApp')
	  .controller('ParentController', parentController);

})(angular);
