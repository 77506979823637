(function(){
	 'use strict';

	angular.module("clientApp")
	.config(["$routeProvider", function($routeProvider) {
		$routeProvider
		.when('/', {
			templateUrl: './pages/login/loginView.html',
			controller: 'loginController',
			controllerAs: 'loginController',
			authorized: false
		})
		.when('/validationInscription/:cle/:id', {
			templateUrl: './pages/page-intermediaire-validation-inscription.html',
			controller: 'loginController',
			controllerAs: 'loginController',
			authorized: false
		})
		.when("/accueil", {
			controller: "AccueilController",
			templateUrl: "./pages/accueil/accueil.html",
			authorized: true
		})
		.when("/retour-signature-consentement", {
			controller: "AccueilController",
			templateUrl: "./pages/accueil/retour-signature-consentement.html",
			authorized: true
		})
		.when("/retour-signature-chambre-individuelle", {
			controller: "HospitController",
			templateUrl: "./pages/hospit/retour-signature-chambre-individuelle.html",
			authorized: true
		})
		.when("/hospitalisations/cequejedoissavoir/:sejourId", {
			controller: "HospitController",
			templateUrl: "./pages/hospit/cequejedoissavoir.html",
			authorized: true
		})
		.when("/hospitalisations/preadmission/:sejourId/:etape?", {
			controller: "HospitController",
			templateUrl: "./pages/hospit/pre-admission.html",
			authorized: true
		})
		.when("/hospitalisations/supplements/:sejourId", {
			controller: "HospitController",
			templateUrl: "./pages/hospit/supplements.html",
			authorized: true
		})
		.when("/hospitalisations/avis/:sejourId", {
			controller: "HospitController",
			templateUrl: "./pages/hospit/avis.html",
			authorized: true
		})
		.when('/hospitalisations/remboursementsdeplacements/:sejourId', {
			templateUrl: './pages/hospit/remboursements-deplacements.html',
			controller: 'HospitController',
			authorized: true
		})
		.when("/hospitalisations/pap", {
			controller: "HospitController",
			templateUrl: "./pages/hospit/personnes-a-prevenir.html",
			authorized: true
		})
		.when("/documents/administratifs", {
			controller: "DossierMedicalController",
			templateUrl: "./pages/documents/doc-administratifs.html",
			authorized: true
		})
		.when("/documents/informations", {
			controller: "ProgrammeSanteController",
			templateUrl: "./pages/documents/informations-medicales.html",
			authorized: true
		})
		.when("/documents/dossier-medical", {
			controller: "DossierMedicalController",
			templateUrl: "./pages/dossier-medical/doc-medicaux.html",
			authorized: true
		})
		.when("/programme-sante/tableau-bord", {
			controller: "ProgrammeSanteController",
			templateUrl: "./pages/programme-sante/tableau-bord.html",
			authorized: true
		})
		.when("/documents/donnees-sante/saisieDonneesMedicales", {
			controller: "ProgrammeSanteController",
			templateUrl: "pages/programme-sante/saisie-donnees-medicales.html",
			authorized: true
		})
		.when("/documents/donnees-sante/courbes", {
			controller: "ChampSaisieController",
			templateUrl: "pages/programme-sante/suivi-courbes.html",
			resolve: {
				chargerCourbe : function(CourbeService, IhealthService) {
//					return IhealthService.synchroniserIhealth().then(function(){
//						return CourbeService.promiseChargerCourbe();
//					});
					return CourbeService.promiseChargerCourbe();
				}
			},
			authorized: true
		})
		.when("/documents/donnees-sante/historique", {
			controller: "ChampSaisieController",
			templateUrl: "pages/programme-sante/suivi-historique.html",
			// TODO non nécessaire mais injecté dans le controller
			resolve: {
				chargerCourbe : function(CourbeService, IhealthService) {
//					return IhealthService.synchroniserIhealth().then(function(){
//						return CourbeService.promiseChargerCourbe();
//					});
					return CourbeService.promiseChargerCourbe();
				}
			},
			authorized: true
		})
//		// TODO : regarder route : ajout programme-sante
//		.when("/iHealth/:sejourId", {
//			controller: "IhealthController",
//			templateUrl: "pages/programme-sante/suivi-iHealth.jsp",
//			authorized: true
//		})
		// TODO : regarder route : ajout programme-sante
		.when("/questionsFrequentes/:programmeSanteId", {
			controller: "ProgrammeSanteController",
			templateUrl: "pages/programme-sante/suivi-content.jsp",
			authorized: true
		})
		.when("/bilanHebdomadaire/:programmeSanteId", {
			controller: "CommunController",
			templateUrl: "pages/programme-sante/suivi-bilan-hebdomadaire.jsp",
			authorized: true
		})
		.when("/messages/messagesProches/", {
			controller: "EchangeController",
			templateUrl: "pages/messages/messages-proches.html",
			authorized: true
		})
		.when("/messages/messagesClinique/:contactMedicalId?", {
			controller: "EchangeController",
			templateUrl: "pages/messages/messages-clinique.html",
			authorized: true
		})
		.when('/admin/:email', {
			templateUrl: './pages/login/loginView.html',
			controller: 'loginController',
			controllerAs: 'loginController',
			authorized: false
		})
		.when('/application/:appli', {
			templateUrl: './pages/application.html',
			controller: 'applicationController',
			controllerAs: 'applicationController',
			authorized: false
		})
		.otherwise({
			redirectTo: "/"
		});
	}]);
})();