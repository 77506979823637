(function(angular){

	function circuitService($http){
		
		this.getCircuits = function(){
			return $http.get('mobile/circuit/circuits-patient');
		}
		
		this.updateEtape = function(etape){
			return $http({method: 'PUT', url: 'mobile/circuit/maj-etape', data: JSON.stringify(etape)});
		} 

		this.updateCircuitPatient = function(circuit){
			var circuitToSend = {};
			circuitToSend.circuitID = circuit.circuitID;
			circuitToSend.afficheHistorique = circuit.afficheHistorique;
			circuitToSend.afficheEnsuite = circuit.afficheEnsuite;
			return $http({method: 'PUT', url: 'mobile/circuit/maj-circuit-patient', data: JSON.stringify(circuitToSend)});
		} 
		
		this.getURLConsentementEclaire = function(etape){
			return $http.post('mobile/circuit/getURLConsentementEclaire', etape.id);
		} 
		
		this.updateEtapeConsentementSigne = function(id, transactionID){
			return $http.post('mobile/circuit/updateEtapeConsentementSigne', {'id': id, 'transactionID': transactionID});
		} 
		
		this.getEtapePatient = function(id){
			return $http.get('mobile/circuit/getEtapePatient?id='+id);
		}
		
	}

	circuitService.$inject = ['$http'];

	angular
	    .module('clientApp')
	    .service('circuitService', circuitService);
	
})(angular);