angular.module("clientApp").factory("DossierMedicalService",  ['$http', 'CommunService', '$window', 'Upload',   function($http, CommunService, $window, Upload) {
	
	return{
		
		// ----- à l'ouverture de la page : verification de l'accès aux données médicales -----
		demandeAccessDossierMedical : function(){
			return $http.get('mobile/accessDonneesMedicales');
		},
		
		modifierTelephone : function(telephone){
			return $http.put('mobile/modifierTelephone', telephone);
		},
		
		demandeLaPoste : function(){
			return $http.get("demandeLaPoste.action").then(function(response) {
				CommunService.fermerPopup("popupLaPoste");
				// reload patient
				$window.location.reload();
			}
		)},
		
		// ----- demande d'envoi du code par SMS -----
		demandeCodeSms : function(){
			return $http.get("mobile/smsActivationDonneesMedicales");
		},
		
		// ----- envoie du code de validation reçu par SMS -----
		submitSms : function(codeValidation){
			return $http({ 
				method: "GET", 
				url: "mobile/validationCodeSmsDonneesMedicales?codeValidation=" + codeValidation
			});
		},
		
		// ------ Partie TimeLine ------
		getTimeLines : function(lipast){
			return $http({ 
				method: "GET", 
				url: "mobile/timeLine/timeLines",
				params: {lipast : lipast}
			});
		},
		
		// ----- Attache une note au dossier médical -----
		attacherNote : function(note){
			return $http({
				method: "POST",
				url: "mobile/timeLine/attacherNote",
				data: $.param({
					"titre": note.titre,
					"contenu": note.contenu,
					"prive": note.prive
				}),
				headers: {"Content-Type": "application/x-www-form-urlencoded"}
			})
		},
		
		// ------- Attache un document au dossier médical ----------
		attacherDocument : function(file, titre, prive){
	        return Upload.upload({ 
	            url: 'mobile/timeLine/uploadDocument-dossier-medical',
	            data: {file: file, 'titre': titre, 'prive': prive}
	        })
		}
		
	}
}]);