(function(angular){

	angular.module('clientApp').component('custFileLink', {
		templateUrl: "./js/component/custFileLink/custFileLinkComponent.html",
		controller: CustFileLinkController,
		bindings: {
			href:'@',
			lang: '@',
			inner:'@?',
			target: '@?',
			alt:'@?',
			clazz:'@?',
			etablissement:'@?',
		}
	});
	CustFileLinkController.$inject = ['parametersService'];

	/**
	 * Directive pour récupérer les clés de parametrage en base et les afficher en html
	 */
	function CustFileLinkController(parametersService) {
		var ctrl = this;
		
		this.$onInit = function () {
			
			parametersService.getParamByKeyLang(this.href, this.lang).then(function(data){
				ctrl.file = (data.value === undefined ? this.href : data.value);
				
			});
			if (this.alt !== undefined){
				parametersService.getParamByKeyLang(this.alt, this.lang).then(function(data){
					ctrl.alt = (data.value === undefined ? this.alt : data.value);
				});
			}
		};
	}
})(angular);